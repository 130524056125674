/**
 * Работа с протоколами
 * protocol.html новый вид протоколов
 * protocolAll.html вид протоколов по просьбе РЖД (временные)
 * protocol.html вид протоколов для аукцина по просьбе РЖД (временные)
 */
angular.module('uetp')
    .component('protocol', {
        template: '<div ng-include="ctrl.templateUrl">',
        bindings: {
            createData: '<',
            editData: '<',
            createFirstData: '<',
            editFirstData: '<',
            procData: '<'
        },
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'desktopSrv', 'propertiesService',
            'cryptography', 'authSrv', 'alertSrv', 'protocolSrv', 'httpSrv', 'dictionarySrv', 'needConfirmSrv', 'vfSrv', 'constants', 'noticeSrv', 'permissionSrv', 'titleSrv', 'proceduresSrv', '$filter', 'dialogSrv',
            function ($scope, $rootScope, $state, $stateParams, desktopSrv, propertiesService,
                      cryptography, authSrv, alertSrv, protocolSrv, httpSrv, dictionarySrv, needConfirmSrv, vfSrv, constants, noticeSrv, permissionSrv, titleSrv, proceduresSrv, $filter, dialogSrv) {
                var ctrl = this;
                $scope.ctrl = ctrl;
                $scope.saveModel = saveModelFn;
                $scope.updateModel = updateModelFn;
                $scope.backHistory = backHistoryFn;
                $scope.isCancelLot = isCancelLotFn;
                $scope.isCancelBiddingsLot = isCancelBiddingsLotFn;
                $scope.showContractSignedLot = showContractSignedLotFn;
                $scope.cancel = cancelFn;
                $scope.fillAllLots = fillAllLotsFn;
                $scope.showCheckEisStatus = showCheckEisStatusFn;
                $scope.changeProcedureDate = changeProcedureDateFn;
                $scope.showMissedContest = showMissedContestFn;
                $scope.showMissedContestStage = showMissedContestStageFn;
                $scope.showProcedurePlace = showProcedurePlaceFn;
                $scope.getMissedContestState = getMissedContestStateFn;
                $scope.showStageDependedMissedContest = showStageDependedMissedContestFn;
                $scope.resetReason = resetReasonFn;
                $scope.resetReasonCode = resetReasonCodeFn;
                $scope.unlimitedCheckDate = unlimitedCheckDate;
                $scope.isUnlimitedLastStage = isUnlimitedLastStage;
                $scope.checkDateInterval = checkDateIntervalFn;
                $scope.checkDateTimeOffers = checkDateTimeOffersFn;
                $scope.clearRejectReason = clearRejectReasonFn;
                $scope.clearContractSigned = clearContractSignedFn;
                $scope.hasRating = hasRatingFn;
                $scope.getAcceptedOnLot = getAcceptedOnLotFn;
                $scope.hasContractSigned = hasContractSignedFn;
                $scope.showMessagePurchase = showMessagePurchaseFn;
                $scope.isDemo = isDemoFn;
                $scope.noticeSrv = noticeSrv;
                $scope.constants = constants;
                $scope.showTassParticipants = showTassParticipantsFn;
                $scope.getSelectDataFinal = getSelectDataFinalFn;
                $scope.setWinnerSelect = setWinnerSelectFn;
                $scope.rollbackSumms = rollbackSummsFn;
                $scope.ajaxActive = false; // вместо submitted этот параметр
                $scope.typeProtocol = '';
                $scope.stageProtocol = '';
                $scope.submitted = false;

                $scope.model = {protocolData: {}, procedure:{}};
                $scope.viewModel = {
                    title: 'Информация о заявках',
                    titleDateTable: 'Дата и время подачи заявок',
                    cancelProc: false
                };
                $scope.dateOptions = {
                    minDate: getServerTimeMsk()
                };
                $scope.dateOptionsNoticeClaimEnds = {
                     minDate: getServerTimeMsk()
                };
                $scope.actionBtns = {
                    isEdit: false,
                    isSave: false,
                    isSign: false,
                    isEditPublic: false
                };
                $scope.getCurrencyPatternByCode = (code) => dictionarySrv.getCurrencyPatternByCode(code);
                $scope.currencyArr = [];
                $scope.toggleMinNoticeClaimEnds = function() {
                  $scope.dateOptionsNoticeClaimEnds.minDate = $scope.noticeClaimEnds ? new Date($scope.noticeClaimEnds) : getServerTimeMsk();
                };
                $scope.processTassParticipants = {};
                $scope.changePrice = changePriceFn;
                $scope.prices_error=[];
                $scope.participantPrices=[];
                $scope.hasPermission = permissionSrv.hasPermission;
                this.$onInit = function () {
                    $scope.isViewOnly = $stateParams.isViewOnly || !$scope.hasPermission();
                    $scope.auth = authSrv.getAuth();
                    $scope.getCabType = desktopSrv.getCabType;
                    $scope.certCheck = authSrv.isCertAvailable;
                    $scope.protocolForm = {};
                    $scope.protf = vfSrv.getVisibleProtocolFields($stateParams.procType);
                    $scope.selectDataAccepted = dictionarySrv.getStaticDictByType('protocolAccepted');
                    $scope.selectDataRussianPriority = dictionarySrv.getStaticDictByType('protocolRussianPriority');
                    $scope.selectDataFinal = dictionarySrv.getStaticDictByType('protocolFinal');
                    $scope.typeRebidding = dictionarySrv.getStaticDictByType('typeRebidding');
                    $scope.protocolAuctionRejectReason = dictionarySrv.getStaticDictByType('protocolAuctionRejectReason');
                    $scope.procType = $stateParams.procType;
                    $scope.operatorSupplyMsg = true;
                    $scope.winnerPaysMsg = true;
                    $scope.isShowDownloadBtn;

                    if (ctrl.procData) {
                        $scope.model.procedure = ctrl.procData.data.result;
                    }
                    if (ctrl.createData) {
                        if (ctrl.createData.data.success) {
                            $scope.isEdit = false;
                            $scope.model.protocolData = angular.copy(ctrl.createData.data.result);
                            $scope.typeProtocol = $scope.model.protocolData.document.type.code;
                            afterInitHandler($scope.model);
                        } else {
                            dialogSrv.openModal('_dialogMessage.html',
                                {message: ctrl.createData.data.errorMessage}).then(function () {
                                history.back();
                            });
                        }
                    } else if (ctrl.editData) {
                        if (ctrl.editData.data.success) {
                            $scope.isEdit = true;
                            $scope.model.protocolData = angular.copy(ctrl.editData.data.result);

                            $scope.typeProtocol = $scope.model.protocolData.document.type.code;

                            if ($stateParams.successSave) {
                                $scope.alertObjProtocol = alertSrv.getSuccessMsgWithBtn("Документ сохранен!");
                            } else if ($stateParams.successSend) {
                                $scope.alertObjProtocol = alertSrv.getSuccessMsgWithBtn('Документ отправлен');
                            }
                            if(($scope.typeProtocol === 'OPEN' || $scope.typeProtocol === 'PARTS' ||
                                $scope.typeProtocol === 'PART1' && $scope.model.protocolData.skipOpenProtocol ||
                                $scope.typeProtocol === 'PART1' && $scope.procType === 'unlimitedprequalification' ||
                                $scope.typeProtocol === 'REZ' && ($scope.procType === 'simplecontest' ||(
                                    (['requestproposals','limitedrequestproposals','requestquotations'].includes($scope.procType) && $scope.model.procedure.simplifiedProcedure)
                                ))) &&
                            	$scope.hasClaims() &&
                            	(!$scope.model.protocolData.tassParticipants || $scope.model.protocolData.tassParticipants.length === 0 ||
                                    $scope.procType === 'unlimitedprequalification' && $stateParams.fromCreate) &&
								$scope.model.protocolData.document.version === 1 &&
                            	$scope.model.protocolData.state &&
                            	$scope.model.protocolData.state.id === 'created'){
                            	$scope.createTassParticipants(true);
                            }

                            afterInitHandler($scope.model);
                            
                        } else {
                            history.back();
                            alert(ctrl.editData.data.errorMessage);
                        }
                    }
                    if($scope.model.protocolData.mchds)
                        propertiesService.getProperty("mchd.url").then((host) => {
                            if (!host.endsWith('/')) host += '/';
                            $scope.mchdUrl = host;
                        });

                    if(!$scope.typeProtocol)
                        $scope.typeProtocol = $scope.model.protocolData.document.type.code;

                    if($scope.model.protocolData.tassParticipants && $scope.model.protocolData.tassParticipants.length > 0 && 
                       ($scope.procType !== 'unlimitedprequalification' || !$stateParams.fromCreate)){
                    	setProcessTassParticipants(false);
                    }

                    if (['opencontest','limitedcontest','openauction','limitedauction','requestquotations','limitedrequestquotations','requestproposals', 'limitedrequestproposals','open2stagecontest'].includes($scope.procType)
                        && ['PARTS','PART1','REZ'].includes($scope.typeProtocol)
                        && !$scope.hasClaims()
                        && $scope.model.procedure.section==='223') {
                        protocolSrv.isRzdCab($stateParams).then((response)=> {
                            if (response.data.success) {
                                $scope.isShowDownloadBtn =  response.data.result;
                            }
                        })
                    }

                    if (['openauction','limitedauction'].includes($scope.procType) &&
                        'REZ'===$scope.typeProtocol && $scope.model.procedure.section==='223') {
                        protocolSrv.isRzdCab($stateParams, true).then((response)=> {
                            if (response.data.success) {
                                $scope.isShowAuctionDownloadDocxBtn =  response.data.result;
                            }
                        })
                    }

                    $scope.stageProtocol = $scope.model.protocolData.document.stage;
                    this.templateUrl = protocolSrv.getTemplateProtocol($stateParams.procType, $scope.typeProtocol);

                    /**
                     * Получение формы валидатором
                     */
                    $scope.getForm = function () {
                        return $scope.protocolForm.form
                    };


                    $scope.editPublic = $scope.model.protocolData.document.version > 1; // если версия больше 1, то идет изменение уже опубликованного

                    $scope.protocol = $scope.model.protocolData.document;
                    $scope.noticeClaimEnds = $scope.model.protocolData.noticeClaimEnds;
                    $scope.russianPriority = $scope.model.protocolData.document.russianPriority;

                    $scope.is223Competitive = proceduresSrv.is223Competitive($scope.model.procedure);
                    $scope.protocolClaimRejectReason = dictionarySrv.getStaticDictByType('protocolClaimRejectReason')
                        .filter(r => r.is223 === $scope.is223Competitive || r.id === $scope.protocol.missedReasonCode);

                    _getBtnVisible($scope.model.protocolData.actions);
                    _initViewModel();
                    _initMissedContest();
                    $scope.prn = protocolSrv.getProtNamesByType($stateParams.procType, $scope.protocol.stage);
                    $scope.viewModel.showIconInProtocol = false;
                    angular.forEach($scope.model.protocolData.document.lots.protocolLotApplication.protocolLotApplications, function (plas) {
                        if(ctrl.procData) {
                            angular.forEach(ctrl.procData.data.result.lots, function (lot){
                                if(lot.ordinalNumber===plas.lotInfo.ordinalNumber)
                                    $scope.currencyArr.push({num: lot.ordinalNumber, val: lot.currencyCode});
                                    // $scope.currencyArr.push(lot.currencyCode);
                            });
                        }
                        if (plas) {
                            angular.forEach(plas.application, function (lot){
                                if (lot.participant && !['fl','nr'].includes(lot.participant.type))
                                    $scope.viewModel.showIconInProtocol = true;
                            });
                        }
                        angular.forEach(plas.application, function (lot){
                            if(!$scope.participantPrices[plas.lotInfo.ordinalNumber])
                                $scope.participantPrices[plas.lotInfo.ordinalNumber] = [];
                            $scope.participantPrices[plas.lotInfo.ordinalNumber].push(lot.applicationNumber);
                        });

                    });
                    if ($scope.model.procedure.number && $scope.model.procedure.type.name) {
                        titleSrv.setTitle($scope.model.procedure.number + ' | ' + $scope.model.procedure.type.name);
                    }

                    if(isAuctionInitSign())
                        initActionSigned();
                }

                $scope.getCurrencyForLot = (num) => $scope.currencyArr.find(c=>c.num===num)?.val

                function isAuctionInitSign(){
                    return $scope.typeProtocol === 'REZ' && ['openpublicoffer', 'openauction','saleauction','limitedauction'].includes($stateParams.procType) && $scope.model.protocolData.document.purchase.procPriceType!=='NO_PRICE';
                }

                function initActionSigned() {
                    $scope.model.protocolData.document.lots.protocolLotApplication.protocolLotApplications.forEach(l => {
                        if (isLotMultipleContracts(l) && l.application && !l.application.find(a=>a.contractSigned)) {
                            l.application[0].contractSigned=true;
                        }
                    });
                }

                function getSelectDataFinalFn(lot, applicationNumber){
                	if($scope.isView)
                		return $scope.selectDataFinal;

                	const winnersLot = [];
                	angular.forEach(lot.application, function (apply) {
                		if(apply.winnerIndication){
                		   winnersLot.push({
                			   winnerIndicationCode: apply.winnerIndication.code,
                			   applicationNumber: apply.applicationNumber
                		   });
                		}
                	});
                    const exclusions = isLotMultipleContracts(lot) ? ['B','N','F'] : ['B','N'];
                  	return $scope.selectDataFinal.filter(function (x) {
                  	  let result = true;
                  	  if(!exclusions.includes(x.code)){
                  	    angular.forEach(winnersLot, function (winner) {
                		  if(winner.winnerIndicationCode === x.code && winner.applicationNumber !== applicationNumber){
                			  result = false;	
                		  }
                	   })
                  	 }
                	 return result;
                  	});
                }

                /**
                 * Возвращаем измененные цены
                 * @param lotNumber номер лота
                 * @param apply заявка
                 * apply.applicationNumber,apply.contractSigned
                 */
                function rollbackSummsFn(lotNumber,apply) {
                    const applicationNumber = apply.applicationNumber;
                    const checkbox = apply.contractSigned;
                    if(!checkbox && (ctrl.editData && ctrl.editData.data.success || ctrl.createData && ctrl.createData.data.success)){
                        let lots = ctrl.editData ? ctrl.editData.data.result.document.lots : ctrl.createData.data.result.document.lots;
                        let srcLot = lots.protocolLotApplication.protocolLotApplications.filter(lot => lot.lotInfo.ordinalNumber===lotNumber);
                        let destLot = $scope.model.protocolData.document.lots.protocolLotApplication.protocolLotApplications.filter(lot => lot.lotInfo.ordinalNumber===lotNumber);
                        if(srcLot && destLot && srcLot.length>0 && destLot.length>0) {
                            let srcAppl = srcLot[0].application.filter(appl => appl.applicationNumber === applicationNumber);
                            let destAppl = destLot[0].application.filter(appl => appl.applicationNumber === applicationNumber);
                            if (srcAppl && srcAppl.length > 0 && destAppl && destAppl.length > 0) {
                                // ценовое предложение
                                destAppl[0].contractSum = srcAppl[0].contractSum;
                                destAppl[0].contractSumNoNDS = srcAppl[0].contractSumNoNDS;
                                delete $scope.prices_error[lotNumber];
                                // поле Сведения о цене
                                destAppl[0].priceInfo = srcAppl[0].priceInfo;
                            }
                        }
                    }
                }

                function setWinnerSelectFn(lot, level1Index){
                	if(!lot.application || $scope.getAcceptedOnLot(lot)<=1){
                		return;
                	}
                	let result = false;
               	   	angular.forEach(lot.application, function (apply) {
                		if(apply.winnerIndication && apply.winnerIndication.code === 'F'){
                	        result = true;	
                  		}
                	});

               	    for (let i = 0; i < lot.application.length; i++) {
               	    	const elName = 'winnerIndication_' + level1Index + '_' + i;
               	    	if($scope.protocolForm.form[elName]) {
                            $scope.protocolForm.form[elName].$setValidity(elName, result);
                            $scope.protocolForm.form[elName].$$element[0].title = !result ? "Не указано значение 'Победитель'." : "";
                            $scope.protocolForm.form[elName].$setDirty();
                        }
               	    } 
                }
                 
                $scope.getAdditionalDisableAccepted = function(claim) {
                    var result;
                    if($scope.procType=='requestproposals')
                        result = $scope.typeProtocol == 'REBIDDING_PART1' && !(claim.rebiddingNumber>0 && claim.rebiddingNumber == $scope.protocol.rebiddingNumber) ||
                            $scope.typeProtocol == 'PART1' && $scope.stageProtocol=='stage2' && claim.stage!='stage2' || $scope.typeProtocol === 'REZ' && !$scope.model.procedure.simplifiedProcedure;
                    else
                        result = (( $scope.procType=='limitedrequestproposals' || $scope.procType=='open2stagecontest')&& $scope.stageProtocol=='stage2' && claim.stage!='stage2')||
                        (( $scope.procType=='opencontest' || $scope.procType=='requestquotations' || $scope.procType=='limitedrequestquotations' || $scope.procType=='limitedcontest') && claim.rebiddingNumber == 0 && $scope.typeProtocol == 'REBIDDING_PART1') ||
                        ($scope.typeProtocol == 'REZ' && !($scope.procType ==='simplecontest' || (['requestquotations','limitedrequestproposals'].includes($scope.procType) && $scope.model.procedure.simplifiedProcedure)));
                   return result;
                };
                $scope.showReason = function (lot){
                    var showRsn=false;
                    angular.forEach($scope.model.protocolData.document.lots.protocolLotApplication.protocolLotApplications, function (plas) {
                        if(lot.lotInfo.ordinalNumber===plas.lotInfo.ordinalNumber){
                            angular.forEach(plas.application, function (apply) {
                            if(apply.accepted && apply.accepted.code === 'F')
                                showRsn = true;
                            });
                        }
                    });
                    return showRsn;
                };
                $scope.getParticipant = function (apply) {
                	var inn = $scope.getParticipantInn(apply);
                    if (apply.participant) {
                        return inn + ', ' + apply.participant.name
                    }
                    return 'Участник ' + inn;
                };
                $scope.displayParticipantSupply = function() {
                    return ['admincab', 'manager', 'organizer'].includes($scope.getCabType()) && ['223','commercial'].includes($scope.model.procedure.section) &&
                        !['simplecontest', 'prequalification', 'unlimitedprequalification', 'saleauction', 'openpublicoffer'].includes($stateParams.procType) &&
                        ['NO_PRICE', 'MAX_PRICE'].includes($scope.model.protocolData.document.purchase.procPriceType) && 'CHANGE_CONTRACT_ADDITIONAL'!==$scope.typeProtocol;
                };
                $scope.getSupply = function (lot, apply) {
                    const lotSupply = $scope.model.protocolData.lotSupplyInfos && $scope.model.protocolData.lotSupplyInfos[lot.lotInfo.ordinalNumber];
                    if(lotSupply && lotSupply[apply.applicationNumber] && ['operatorSupply', 'customerSupply'].includes(lotSupply[apply.applicationNumber].depositPay)) {
                        if(lotSupply[apply.applicationNumber].providedBankGuarantee) {
                            return "Обеспечение: предоставление банковской гарантии";
                        } else if(lotSupply[apply.applicationNumber].depositPay==='operatorSupply')
                            return "Обеспечение: внесены денежные средства на лицевой счет участника на счете Оператора КОМИТА ЭТП";
                    }
                };

                $scope.isFinalProtocol = function() {
                    return 'REZ' === $scope.typeProtocol || ['PART1','PARTS'].includes($scope.typeProtocol) && $scope.model.protocolData.document.missedContest;
                }

                $scope.showOperatorSupplyInfo = function() {
                    return !$scope.isView && $scope.getCabType()==='organizer' && $scope.operatorSupplyMsg && $scope.isFinalProtocol() &&
                        $scope.model?.protocolData?.state?.id !== "published" && $scope.model.protocolData.lotSupplyInfos && Object.keys($scope.model.protocolData.lotSupplyInfos)
                            .find(lotSupplyKey => !isCancelLotNumberFn(lotSupplyKey) && Object.values($scope.model.protocolData.lotSupplyInfos[lotSupplyKey]).find(ls => ls.depositPay === 'operatorSupply'));
                }

                $scope.showWinnerPaysInfo = function() {
                    return !$scope.isView && $scope.getCabType()==='organizer' && $scope.winnerPaysMsg && $scope.isFinalProtocol() &&
                        $scope.model?.protocolData?.state?.id !== "published" && $scope.model?.procedure?.lots?.some(lot => lot?.tariffType == 'winnerPays' && lot?.winnerPayment > 0);
                }

                $scope.closeSupplyMsg = function () {
                    $scope.operatorSupplyMsg = false;
                }

                $scope.closeWinnerPaysMsg = function () {
                    $scope.winnerPaysMsg = false;
                }

                $scope.getParticipantInn = function (apply) {
                    if (apply.participant) {
                        var participant = apply.participant;
                        var inn = '';
                        if (participant.orgUL) {
                            inn = participant.orgUL.inn
                        } else if (participant.orgFL) {
                            inn = participant.orgFL.inn
                        } else if (participant.orgIP) {
                            inn = participant.orgIP.inn
                        } else if (participant.orgNonResident) {
                            inn = participant.orgNonResident.code
                        }
                        return inn;
                    }
                    return apply.applicationNumber;
                };

                $scope.showIconByParticipantType = function (apply) {
                    var result = true;
                    if (apply && apply.participant && (apply.participant.type === 'fl' || apply.participant.type === 'nr') )
                        result = false;
                    return result;
                };

                $scope.claimNumber = function (apply) {
                    return apply.applicationNumber;
                };
                $scope.rebiddingNumber = function (apply) {
                	if($scope.model.protocolData.document.type.code != 'REZ' &&
                	   $scope.model.protocolData.document.type.code != 'REBIDDING_PART1' ){
                	   return $scope.model.protocolData.document.rebiddingNumber;
                	}else{
                       return apply.rebiddingNumber;
                	}
                };
                $scope.deleteProtocol = function () {
                    if (!$scope.ajaxActive) {
                        $scope.ajaxActive = true;
                        protocolSrv.deleteProtocolById($stateParams).then(function (response) {
                            if (response.data.success) {
                                backHistoryFn()
                            } else {
                                $scope.ajaxActive = false;
                                $scope.alertObjProtocol = alertSrv.getAlertObj(response);
                            }
                        }, function (reason) {
                            $scope.ajaxActive = false;
                            $scope.alertObjProtocol = alertSrv.getAlertObj(reason);
                        })
                    }
                };
                $scope.sortByApplicationNumber = function(apply){
                    return parseInt(apply.applicationNumber)
                };

                function _initMissedContest(){
                    $scope.viewModel.cancelProc = protocolSrv.isCancelProc($scope.typeProtocol,$stateParams.procType,$scope.model.protocolData);
                    if($scope.typeProtocol ==='REZ' && ['openpublicoffer', 'openauction','saleauction','limitedauction'].includes($stateParams.procType) && protocolSrv.cancelProcedure($scope.model.protocolData)){
                        $scope.resetReason();
                    }
                }

                function _initViewModel() {
                    if (['openpublicoffer','openauction','saleauction','limitedauction'].includes($stateParams.procType) && $scope.typeProtocol === 'REZ') {
                        $scope.viewModel.title = "Информация о ценовых предложениях";
                        $scope.viewModel.titleDateTable = "Дата и время подачи ценовых предложений";
                    } else {
                        $scope.viewModel.title = "Информация о заявках";
                    }
                }

                function hasRatingFn(protocolType,lot) {
                    return (($scope.stageProtocol==='stage2' && protocolType==='PART1' && ['requestproposals','limitedrequestproposals','open2stagecontest'].includes($scope.procType)) ||
                        (!$scope.stageProtocol && protocolType==='PART1' && ['opencontest','requestquotations','limitedcontest','limitedrequestquotations'].includes($scope.procType)) ||
                        (protocolType==='PARTS' && $scope.procType==='offerplacement') || protocolType==='REZ' ||
                        protocolType==='CHANGE_CONTRACT_ADDITIONAL' && !['prequalification', 'unlimitedprequalification', 'offerplacement','openpublicoffer','openauction','saleauction','limitedauction'].includes($scope.procType))
                        && hasAcceptedOnLot(lot);
                }
                function hasAcceptedOnLot(lot){
                    return getAcceptedOnLotFn(lot)>0;
                }
                function getAcceptedOnLotFn(lot){
                    let accCnt=0;
                    angular.forEach($scope.model.protocolData.document.lots.protocolLotApplication.protocolLotApplications, function (plas) {
                        if(lot.lotInfo.ordinalNumber===plas.lotInfo.ordinalNumber) {
                            angular.forEach(plas.application, function (apply) {
                                if (apply.accepted.code==='T')
                                    accCnt++;
                            });
                        }
                    });
                    return accCnt;
                }

                function hasContractSignedFn(lot,appl){
                    let lots = $scope.model.protocolData.document.lots;
                    if(lots && lots.protocolLotApplication && lots.protocolLotApplication.protocolLotApplications) {
                        let currentLot = lots.protocolLotApplication.protocolLotApplications
                            .filter(plas => lot.lotInfo.ordinalNumber === plas.lotInfo.ordinalNumber);
                        if (currentLot && currentLot.length > 0) {
                            return currentLot[0].application.find(apply => appl ? appl.applicationNumber===apply.applicationNumber && apply.contractSigned === true : apply.contractSigned === true);
                        }
                    }
                    return false;
                }

                $scope.isShowExportBtn = function (){
                    if(['DOP','DOPONLINE','REBIDDING_OPEN'].includes($scope.typeProtocol))
                        return true;
                    if($scope.typeProtocol==='REBIDDING_PART1')
                        return false;
                    if(['open2stagecontest','opencontest','limitedcontest','requestquotations','limitedrequestquotations','requestproposals','limitedrequestproposals','prequalification'].includes($stateParams.procType) && $scope.typeProtocol!=='OPEN')
                        return false;
                    return !(['offerplacement', 'limitedcontestqualification'].includes($stateParams.procType) && $scope.typeProtocol === 'REZ');

                };

                /**
                 * Активация кнопок в зависимости от разрешнных действий сервера
                 * @param actions - действия которые можно выпонить с документом
                 * @private
                 */
                function _getBtnVisible(actions) {
                    if ($scope.isViewOnly) {
                        $scope.isView = true;
                    } else {
                        if ($scope.isEdit) {
                            if (actions && actions.length > 0) {
                                angular.forEach(actions, function (action) {
                                    if (action.id.indexOf('editProtocol') >= 0) {
                                        $scope.actionBtns.isEdit = true;
                                    } else if (action.id.indexOf('publishProtocol') >= 0) {
                                        $scope.actionBtns.isSign = true;
                                    } else if (action.id.indexOf('deleteProtocol') >= 0) {
                                        $scope.actionBtns.idDelete = true;
                                    } else if (action.id.indexOf('editPublishedProtocol') >= 0) {
                                        $scope.actionBtns.isEditPublic = true;
                                        $scope.isView = true;
                                    }
                                })
                            } else {
                                $scope.isView = true
                            }
                        } else {
                            $scope.actionBtns.isSave = true;
                        }
                    }
                }

                function saveModelFn(model) {
                    if (validateForm() && !$scope.ajaxActive) {
                        let data = angular.copy(model);
                        beforeSaveHandler(data);
                        $scope.ajaxActive = true;
                        if ($scope.editPublic) {
                            protocolSrv.saveProtocolPublicFn($stateParams, data.protocolData).then(function (response) {
                                if (response.data.success) {
                                    $state.go('openProtocol', {
                                        procId: $stateParams.procId,
                                        procType: $stateParams.procType,
                                        protocolId: data.protocolData.id,
                                        successSave: true
                                    }, {location: 'replace'})
                                } else {
                                    $scope.ajaxActive = false;
                                    $scope.alertObjProtocol = alertSrv.getAlertObj(response);
                                }
                            });
                        } else {
                            protocolSrv.saveProtocolFn($stateParams, data.protocolData, $scope.typeProtocol).then(function (response) {
                                if (response.data.success) {
                                    $state.go('openProtocol', {
                                        procId: $stateParams.procId,
                                        procType: $stateParams.procType,
                                        protocolId: data.protocolData.id,
                                        successSave: true
                                    }, {location: 'replace'})
                                } else {
                                    $scope.ajaxActive = false;
                                    $scope.alertObjProtocol = alertSrv.getAlertObj(response);
                                }
                            });
                        }

                    }
                }

                function afterInitHandler(model){
                    // Для Протокола изменений условий договора заполняем поля commodityAmount и contractExecutionTerm победителя
                    if($scope.typeProtocol==='CHANGE_CONTRACT_ADDITIONAL'){
                        model.protocolData.document.lots.protocolLotApplication.protocolLotApplications.forEach(function(lot){
                            if(lot.application)
                                lot.application.forEach(function(appl){
                                    if(appl && appl.contractSigned){
                                        lot.commodityAmount = appl.commodityAmount;
                                        lot.contractExecutionTerm = appl.contractExecutionTerm;
                                    }
                                });
                        });
                        if($scope.model.protocolData.parentProtocolId)
                            $scope.parentProtocolId = $scope.model.protocolData.parentProtocolId;
                    }
                }

                function beforeSaveHandler(model){
                    // Для Протокола изменений условий договора заполняем поля commodityAmount и contractExecutionTerm победителя
                    if($scope.typeProtocol==='CHANGE_CONTRACT_ADDITIONAL'){
                        model.protocolData.document.lots.protocolLotApplication.protocolLotApplications.forEach(function(lot){
                            if(lot.application) {
                                lot.application.forEach(function (appl) {
                                    if (appl && appl.contractSigned) {
                                        appl.commodityAmount = appl.contractSigned ? lot.commodityAmount : "";
                                        appl.contractExecutionTerm = appl.contractSigned ? lot.contractExecutionTerm : "";
                                    } else {
                                        delete appl.commodityAmount;
                                        delete appl.contractExecutionTerm;
                                    }
                                    // дублируем цену в contractSumNoNDS
                                    if($scope.isSimpleContest44()){
                                        appl.contractSumNoNDS = appl.contractSum;
                                    }
                                });

                            }
                            delete lot.commodityAmount;
                            delete lot.contractExecutionTerm;
                        });
                        if($scope.parentProtocolId)
                            model.protocolData.parentProtocolId = $scope.parentProtocolId;
                    }
                }

                function updateModelFn(model) {
                    if (validateForm() && !$scope.ajaxActive) {
                        let data = angular.copy(model);
                        beforeSaveHandler(data);
                        $scope.ajaxActive = true;
                        protocolSrv.updateProtocolFn($stateParams, data.protocolData).then(function (response) {
                            if (response.data.success) {
                                $scope.alertObjProtocol = alertSrv.getAlertObj(response, "Документ сохранен!");
                            } else {
                                $scope.alertObjProtocol = alertSrv.getAlertObj(response);
                            }
                            $scope.ajaxActive = false;
                        });
                    }
                }

                /**
                 * Возврат к предыдущему состоянию (url)
                 */
                function backHistoryFn() {
                    history.back();
                }

                function isCancelLotFn(lot) {
                    return $scope.model.protocolData.stateLots[lot.lotInfo.ordinalNumber] === 'canceled';
                }
                function isCancelLotNumberFn(lotNumber) {
                    return $scope.model.protocolData.stateLots[lotNumber] === 'canceled';
                }
                function isCancelBiddingsLotFn(lot){
                    return $scope.model.protocolData.stateLots[lot.lotInfo.ordinalNumber] === 'cancelBiddings';
                }

                function showContractSignedLotFn(lot){
                    if($scope.isAcceptNotSet(lot)) return false;
                    if(['openpublicoffer','openauction','saleauction','limitedauction'].includes($stateParams.procType))
                        return (($scope.typeProtocol ==='PARTS' && getAcceptedOnLotFn(lot)===1) ||
                        isLotMultipleContracts(lot) && ($scope.typeProtocol ==='REZ' || $scope.typeProtocol ==='PARTS' && $scope.model.protocolData.document.missedContest));
                    else if(['opencontest', 'requestproposals', 'requestquotations', 'open2stagecontest', 'limitedcontest', 'limitedrequestproposals', 'limitedrequestquotations','limitedcontestqualification'].includes($stateParams.procType)
                        && (['PART1', 'PARTS'].includes($scope.typeProtocol) && $scope.model.protocolData.document.missedContest || $scope.typeProtocol === 'REZ' && isLotMultipleContracts(lot) ) && getAcceptedOnLotFn(lot)>0)
                        return true;
                    return $stateParams.procType !== 'prequalification' && $scope.typeProtocol === 'REZ' && $scope.protf.winner;
                }

                $scope.exportProtocol = function downloadProtocolFn(protocolType, protocolId) {
                    protocolSrv.exportProtocolById($stateParams.procType, $stateParams.procId, protocolType, protocolId);
                };

                /**
                 * Подпись и отправка
                 */
                $scope.signSendData = function () {
                    if ($scope.auth.certId || $scope.auth.withoutCertEnabled ) {
                        let data = angular.copy($scope.model);
                        if (validateForm(true) && !$scope.ajaxActive) {
                            beforeSaveHandler(data);
                            $scope.ajaxActive = true;
                            protocolSrv.updateProtocolFn($stateParams, data.protocolData).then(function (response) {
                                if (response.data.success) {
                                    protocolSrv.signAndSend($stateParams, data.protocolData, $scope.auth.certId).then(function (response) {
                                        if (response.data.success) {
                                            $state.go('openProtocol', {
                                                procId: $stateParams.procId,
                                                procType: $stateParams.procType,
                                                protocolId: data.protocolData.id,
                                                successSave: null,
                                                successSend: true
                                            }, {location: 'replace'});
                                        } else {
                                            if(response.data.errorCode==='427') {
                                                $scope.errorMsg = response.data.errorMessage.replace('\n','<br/>');
                                                setTimeout(() => delete $scope.errorMsg, 7000);
                                            } else
                                                $scope.alertObjProtocol = alertSrv.getAlertObj(response);
                                        }
                                        $scope.ajaxActive = false;
                                    }, function (reason) {
                                        if (typeof reason === 'string') {
                                            $scope.alertObjProtocol = alertSrv.getErrorMsgWithBtn(reason);
                                        } else {
                                            $scope.alertObjProtocol = alertSrv.getAlertObj(reason);
                                        }
                                        $scope.ajaxActive = false;
                                    });
                                } else {
                                    $scope.alertObjProtocol = alertSrv.getAlertObj(response);
                                }
                            })
                        }
                    } else {
                        $scope.ajaxActive = false;
                        $scope.alertObjProtocol = alertSrv.getErrorMsgWithBtn('У вас не установлен сертификат.');
                    }
                };
                $scope.editPublicProc = function () {
                    $state.go('createEditPublishedProtocol', {
                        procId: $stateParams.procId,
                        procType: $stateParams.procType,
                        protocolId: $scope.model.protocolData.id
                    }, {location: 'replace'});
                };
                $scope.uploadSuccess = function (result) {
                    if (!$scope.model.protocolData.document.attachment) {
                        $scope.model.protocolData.document.attachment = [];
                    }
                    if($scope.model.protocolData.document.attachment.length ) {
                        var error = false;
                        angular.forEach($scope.model.protocolData.document.attachment, function (app) {
                            if (app.fileName === result[0].fileName) {
                                error = true;
                            }
                        });
                        if(error){
                            $scope.ctrl.alertObjAttach = alertSrv.getErrorMsgWithBtn("Файл " + result[0].fileName + " не добавлен. <br />" +
                                "Файл с таким именем уже добавлен к этому документу.");
                        } else {
                            $scope.model.protocolData.document.attachment.push({
                                fileName: result[0].fileName,
                                id: result[0].id
                            })
                        }

                    }
                    else {
                        $scope.model.protocolData.document.attachment.push({
                            fileName: result[0].fileName,
                            id: result[0].id
                        })
                    }

                };
                $scope.deleteAttach = function (index) {
                    $scope.model.protocolData.document.attachment.splice(index, 1);
                };
                $scope.downloadFile = function (file) {
                    var url = '/fs/file/' + $stateParams.procType + '/' + $stateParams.procId + '/protocols/' + $stateParams.protocolId + '/files/' + file.id;
                    httpSrv.downloadFile(url);
                };

                function clearRejectReasonFn(apply) {
                    if (apply.accepted.code === 'T') {
                        apply.rejectionReason = '';
                        apply.rejectionReasonCode = '';
                    } else {
                        if (apply.accepted.code === 'N') {
                            apply.rejectionReason = '';
                            apply.rejectionReasonCode = '';
                        }

                        if(apply.rating) apply.rating='';
                        // сбрасываем победителя
                        delete apply.winnerIndication;
                        delete apply.contractSigned;
                        delete apply.russianPriority;
                    }

                    missedContestChange();
                }

                function clearContractSignedFn(lot) {
                    angular.forEach(lot.application, function (apply) {
                        apply.contractSigned = '';
                    });
                }

                function missedContestChange() {
                	$scope.viewModel.cancelProc = ($scope.typeProtocol ==='OPEN' || $scope.typeProtocol ==='PART1'|| $scope.typeProtocol ==='PART2'|| $scope.typeProtocol ==='PARTS'|| $scope.typeProtocol ==='REZ')
                    ? protocolSrv.cancelProcedureOnAccepted($scope.model.protocolData) : false;
                    
                    if(!$scope.viewModel.cancelProc){
                    	$scope.model.protocolData.document.missedContest = null;
                    	$scope.model.protocolData.document.missedReason = '';
                        $scope.model.protocolData.document.missedReasonCode = '';
                    }
                }

                function resetReasonFn() {
                    if ($scope.protocol.missedReasonCode === 'OTHER') {
                        $scope.model.protocolData.document.missedReason = '';
                    } else {
                        $scope.model.protocolData.document.missedReason = getProtocolClaimRejectReasonNameByIdFn($scope.protocol.missedReasonCode);
                    }
                }

                function resetReasonCodeFn() {
                    //UETP-9523
                    if($scope.typeProtocol==='PART1' && !$scope.model.protocolData.document.missedContest) {
                        clearAllContractSigned();
                    }
                    $scope.model.protocolData.document.missedReasonCode = '';
                    $scope.model.protocolData.document.missedReason = '';
                }

                function clearAllContractSigned(){
                    $scope.model.protocolData.document.lots.protocolLotApplication.protocolLotApplications.forEach(function (lot) {
                        if (lot.application) {
                            lot.application.forEach(function (appl) {
                                delete appl.contractSigned;
                            })
                        }
                    });
                }

                function validateForm(checkAttach) {
                    if($scope.typeProtocol==='CHANGE_CONTRACT_ADDITIONAL' && $scope.model.protocolData.document.purchase.procPriceType!=='NO_PRICE'){
                        checkPrices();
                        if($scope.prices_error && $scope.prices_error.length>0 && Object.keys($scope.prices_error).length>0) {
                            $scope.alertObjProtocol = alertSrv.getErrorMsgWithBtn("Необходимо заполнить обязательные поля на форме. ", 'Перейти', $scope.focusFirstInvalid, null);
                            return false;
                        }
                    }

                    if ($scope.protocolForm.form.$invalid) {
                        $scope.submitted = true;
                        if ($scope.protocolForm.form.$error.required) {
                            $scope.alertObjProtocol = alertSrv.getErrorMsgWithBtn('Необходимо заполнить обязательные поля на форме. ', 'Перейти', $scope.focusFirstInvalid, null);
                        }
                        else {
                            var errorArr = Object.keys($scope.protocolForm.form.$error).length > 0 ? $scope.protocolForm.form.$error[Object.keys($scope.protocolForm.form.$error)[0]] : [];
                            if (errorArr.length > 0 && errorArr[0].$$element && errorArr[0].$$element[0] && errorArr[0].$$element[0].title && errorArr[0].$$element[0].title.length>0) {
                                $scope.alertObjProtocol = alertSrv.getErrorMsg(errorArr[0].$$element[0].title);
                            }
                        }
                        return false;
                    }
                    if (checkAttach) {
                        if (!$scope.model.protocolData.document.attachment || $scope.model.protocolData.document.attachment.length === 0) {
                            $scope.alertObjProtocol = alertSrv.getErrorMsgWithBtn("Протокол не опубликован. Для публикации добавьте минимум одно вложение.");
                            return false;
                        }
                    }

                    // UETP-7414 Добавить сравнение дат этапа и подпиcания при сохранении протокола
                    const procedureDate = $filter('date')($scope.model.protocolData.document.procedureDate, 'yyyy-MM-dd');
                    const protocolSignDate = $filter('date')($scope.model.protocolData.document.protocolSignDate, 'yyyy-MM-dd');
                    if(procedureDate && protocolSignDate && new Date(procedureDate).getTime()>new Date(protocolSignDate).getTime()) {
                        $scope.alertObjProtocol = alertSrv.getErrorMsgWithBtn("Дата проведения этапа процедуры должна быть не позже даты подписания протокола.");
                        return false;
                    }


                    // Чистим claimEndsSecondStage, если допущено меньше 2 участников
                    if(['requestproposals', 'limitedrequestproposals', 'open2stagecontest'].includes($scope.procType) && $scope.typeProtocol === 'PART1' && !$scope.hasSecondStage()){
                        delete $scope.model.protocolData.document.claimEndsSecondStage;
                        delete $scope.protocol.claimEndsSecondStage;
                    }

                    return true;
                }

                $scope.downloadByType = function (type, data) {
                    protocolSrv.downloadByType(type, $stateParams, data, $scope.model.protocolData.document.type.code, $scope.model.protocolData.document);
                };

                $scope.downloadByParticipant = function (type, data, claim, rebiddingNumber) {
                    protocolSrv.downloadByParticipant(type, $stateParams, data, claim, $scope.model.protocolData.document, rebiddingNumber);
                };

                $scope.downloadProtocolById = function () {
                	protocolSrv.downloadProtocolById($stateParams, $scope.model.protocolData.document);
                };
                $scope.downloadAucRezDocxById = (lotId) => protocolSrv.downloadAucRezDocxById($stateParams, lotId);
                
                $scope.downloadTassParticipants = function () {
                	var fileInfo = $scope.getFileInfo();
                 	protocolSrv.downloadTassParticipants($stateParams, fileInfo);
                };
                $scope.createTassParticipants = function (isAuto) {
                	if($scope.processTassParticipants['top']) {
                		return;
                	}
                	setProcessTassParticipants(true);
                 	protocolSrv.createTassParticipants($stateParams, isAuto).then(function (response) {
                        if (response.data.success && response.data.result) {
                        	$scope.alertHeadProtocol = alertSrv.getSuccessMsgWithBtn("Файл с результатами проверки компаний сформирован");
                       	    $scope.model.protocolData.tassParticipants = angular.copy(response.data.result);
                        } else {
                        	$scope.alertHeadProtocol = alertSrv.getErrorMsgWithBtn("Файл с результатами проверки компаний не сформирован");
                        }
                        setProcessTassParticipants(false);
                    });
                };
                
                $scope.downloadTassParticipantsByInn = function (inn) {
                	var fileInfo = $scope.getFileInfo(inn);
                 	protocolSrv.downloadTassParticipants($stateParams, fileInfo);
                };
                $scope.createTassParticipantsByInn = function (isAuto, inn) {
                	if($scope.processTassParticipants[inn]) {
                		return;
                	}
                	$scope.processTassParticipants[inn] = true;
                 	protocolSrv.createTassParticipants($stateParams, isAuto, inn).then(function (response) {
                        if (response.data.success && response.data.result) {
                        	$scope.alertParticipantProtocol = alertSrv.getSuccessMsgWithBtn("Файл с результатами проверки компании сформирован");
                        	if($scope.showTassParticipants(inn)){
	                        	angular.forEach($scope.model.protocolData.tassParticipants, function (item, key, tassParticipants) {
	                                if(inn == item.inn){
	                                	tassParticipants[key] = angular.copy(response.data.result);
	                                }
	                            });
                        	}else{
                        		$scope.model.protocolData.tassParticipants.push(angular.copy(response.data.result));
                        	}
                        } else {
                        	$scope.alertParticipantProtocol = alertSrv.getErrorMsgWithBtn("Файл с результатами проверки компании не сформирован");
                        }
                        $scope.processTassParticipants[inn] = false;
                    });
                };
                
                $scope.getFileInfo = function (inn) {
                    var result = null;
                    angular.forEach($scope.model.protocolData.tassParticipants, function (item) {
                        if(inn && inn == item.inn){
                        	result = angular.copy(item);
                        }else if(!inn && !item.inn){
                        	result = angular.copy(item);
                        }
                    });
                    return result;
                };
               
                function showTassParticipantsFn(inn) {
                    return $scope.getFileInfo(inn) !=null ;
                }
                
                function setProcessTassParticipants(value) {
                	$scope.processTassParticipants['top'] = value;
                	angular.forEach($scope.model.protocolData.tassParticipants, function (item) {
                        if(item.inn){
                        	$scope.processTassParticipants[item.inn] = value;
                        }
                    });
                }
                $scope.isSpin = function (inn) {
                   return $scope.processTassParticipants['top'] || inn && $scope.processTassParticipants[inn];
                };
                
                $scope.hasClaims = function (){
                    let result = false;
                    angular.forEach($scope.model.protocolData.document.lots.protocolLotApplication.protocolLotApplications, function (item) {
                        if(item.application!=null && item.application.length>0) result = true;
                    });
                    return result;
                };
                $scope.isOnlineRebidding = () => $scope.model.protocolData.additionalProtocolType==='online' && $scope.typeProtocol === 'REBIDDING_OPEN';
                $scope.hasSecondStage = () => {
                    let result = false;
                    angular.forEach($scope.model.protocolData.document.lots.protocolLotApplication.protocolLotApplications, (item) => {
                        if (item.application!=null && item.application.length>1 && item.application.filter(appl => appl.accepted.code === 'T').length>1) {
                            result = true;
                        }
                    })
                    return result;
                };

                $scope.focusFirstInvalid = function () {
                    var form = $scope.protocolForm.form,
                        init = function (element) {
                            desktopSrv.scollTop();
                            setTimeout(function () {
                                element.focus();
                            }, 100)
                        };

                    if (form.$invalid) {
                        // валидация обязательных полей.
                        if (form.$error && form.$error.required && form.$error.required[0]
                            && form.$error.required[0].$$element && form.$error.required[0].$$element[0]) {
                            init(form.$error.required[0].$$element[0])
                        } else {
                            // валидация некорректных
                            var errorArr = Object.keys(form.$error).length > 0 ? form.$error[Object.keys(form.$error)[0]] : [];
                            if (errorArr.length > 0 && errorArr[0].$$element && errorArr[0].$$element[0]) {
                                init(errorArr[0].$$element[0])
                            }
                        }
                    }
                };

                function cancelFn() {
                    if ($scope.protocolForm.form.$dirty && !$scope.isView) {
                        needConfirmSrv.call(backHistoryFn, [], 'Внесенные изменения сохранены не будут');
                    } else {
                        backHistoryFn();
                    }
                }

                /**
                 * Утсанвоить для всех лотов как в первом
                 * @param param - куда установить
                 */
                function fillAllLotsFn(lot, param) {
                    if($scope.isView) return;
                    var value = lot[param];
                    angular.forEach($scope.model.protocolData.document.lots.protocolLotApplication.protocolLotApplications, function (lot) {
                        lot[param] = value;
                    });
                }

                function unlimitedCheckDate(isEndDate){
                    if(isEndDate && $scope.model.procedure.type.id === "unlimitedprequalification")
                        unlimitedCheckDateFn();
                }

                function unlimitedCheckDateFn() {
                    return noticeSrv.doUnlimitedCheckDate($scope.protocolForm.form,new Date(),angular.copy($scope.model.procedure.submissionClosePKODateTimeEnds),
                        angular.copy($scope.model.protocolData.document.unlimitedNextStageClaimOpeningDateTime),'unlimitedNextStageClaimOpeningDateTime');
                }

                function isUnlimitedLastStage(){
                    return $scope.model.protocolData.document.unlimitedNextStageClaimOpeningDateTime==null && new Date()>$scope.model.procedure.submissionClosePKODateTimeEnds;
                }

                /**
                 * Отображение информационного окна
                 */
                function showCheckEisStatusFn(procedure) {
                    var value = false;
                    if($scope.protf.showNeedCheck && $scope.typeProtocol ==='OPEN' && !$scope.isView){
                        if(procedure)
                            if(!procedure.commercial){
                                if(procedure.customer.customerType === 'head' || procedure.customer.customerType === 'dzo'){
                                    if(procedure.customer.login!=null){
                                        value = true;
                                    }
                                }else{
                                    if(procedure.customer.parent){
                                       if(procedure.customer.parent.login){
                                           value = true;
                                       }
                                    }
                                }
                            }
                    }
                    return value;
                }

                function changeProcedureDateFn(){
                    $scope.model.protocolData.document.protocolSignDate = $scope.model.protocolData.document.procedureDate;
                }

                function showMissedContestFn(procType, protocolType){
                    return !(procType==='unlimitedprequalification' || protocolType==='REBIDDING_NOTICE' || protocolType==='REBIDDING_OPEN' || protocolType==='REBIDDING_PART1');
                }

                function showMissedContestStageFn(){
                    return !($scope.typeProtocol==='OPEN' && ['limitedrequestproposals','requestproposals','open2stagecontest'].includes($scope.model.protocolData.document.purchase.typeCode) && $scope.stageProtocol==='stage2');
                }

                function showProcedurePlaceFn(procType, protocolType){
                    return ['requestquotations','limitedrequestquotations','opencontest','limitedcontest','requestproposals','limitedrequestproposals'].includes(procType) &&
                            ['REZ','PART1','REBIDDING_PART1'].includes(protocolType) ||
                            procType==='open2stagecontest' && ['REZ','PART1','PART2'].includes(protocolType) ||
                            procType==='prequalification' && ['PART1','PARTS'].includes(protocolType) ||
                           ['limitedcontestqualification','offerplacement'].includes(procType) && ['OPEN','PARTS','REZ'].includes(protocolType) ||
                            protocolType==='CHANGE_CONTRACT_ADDITIONAL' && !['prequalification', 'unlimitedprequalification', 'offerplacement'].includes(procType);
                }

                function getMissedContestStateFn(protocolType){
                    var res = (protocolType==='OPEN' && getApplCnt($scope.model.protocolData.document.lots.protocolLotApplication.protocolLotApplications)===0) ||
                           (protocolType!=='OPEN' && showUnheldPurchase($scope.model.protocolData.document.lots.protocolLotApplication.protocolLotApplications));
                    if(!res) {
                        $scope.model.protocolData.document.missedContest = null;
                        $scope.model.protocolData.document.missedReason = '';
                        $scope.model.protocolData.document.missedReasonCode = '';
                    }
                    return res;
                }

                function showStageDependedMissedContestFn() {
                    if(!(['limitedrequestproposals','requestproposals','open2stagecontest'].includes($scope.model.protocolData.document.purchase.typeCode) && ['PART1','REBIDDING_PART1'].includes($scope.typeProtocol) && "stage2" === $scope.stageProtocol))
                        return true;
                    return !$scope.model.protocolData.document.lots.protocolLotApplication.protocolLotApplications.find(l=>
                        l.application.filter(appl=>appl.accepted.code==='T' || $scope.model.protocolData?.lotStage1Claims[l.lotInfo.ordinalNumber]?.includes(Number(appl.applicationNumber))).length>1);
                }

                function getApplCnt(appls){
                    var applCnt=0;
                    angular.forEach(appls, function (appl) {
                        if(appl.application) applCnt++;
                    });
                    return applCnt;
                }

                function showUnheldPurchase(appls){
                    var showUnheldPurchase=true;
                    angular.forEach(appls, function (appl) {
                        var minimumAcceptedCnt=0;
                        angular.forEach(appl.application, function (app) {
                            if(app.accepted.code && app.accepted.code==='T'){
                                minimumAcceptedCnt++;
                                if(minimumAcceptedCnt>1)
                                    showUnheldPurchase = false;
                            }
                        });
                    });
                    return showUnheldPurchase;
                }

                function checkDateIntervalFn(begin, end, fld){
                    var errorTitie = "" , errorValue = false, beginEl = $scope.protocolForm.form[begin].$$element[0].value, endEl = $scope.protocolForm.form[end].$$element[0].value;

                    if(beginEl && endEl){
                        beginEl = beginEl.replace(/(\d+).(\d+).(\d+)/, '$3/$2/$1');
                        endEl = endEl.replace(/(\d+).(\d+).(\d+)/, '$3/$2/$1');
                        var startDate = new Date(beginEl), endDate = new Date(endEl);
                        if(startDate>=endDate) {
                            errorValue = true;
                            errorTitie = "Дата начала подачи предложений должна быть строго меньше даты и времени окончания подачи предложений";
                            $scope.protocolForm.form[fld].$setValidity(fld, !errorValue);
                            if(errorValue) $scope.protocolForm.form[fld].$setDirty();
                            $scope.protocolForm.form[fld].$$element[0].title = errorValue?errorTitie:"";
                        } else {
                            errorValue = false;
                            errorTitie = "";
                        }
                    }

                    return errorValue;
                }

                function checkDateTimeOffersFn(date0, date1, date2, date3, fld, rebiddingType){
                    var errorTitie = "" , errorValue = false, beginOffer = $scope.protocolForm.form[date0], beginEl = $scope.protocolForm.form[date1], endEl = $scope.protocolForm.form[date2], resultEl = $scope.protocolForm.form[date3];

                    if(rebiddingType && beginOffer && beginEl && beginOffer.$$element[0].value && beginEl.$$element[0].value){
                        beginOffer = beginOffer.$$element[0].value.replace(/(\d+).(\d+).(\d+)/, '$3/$2/$1');
                        beginEl = beginEl.$$element[0].value.replace(/(\d+).(\d+).(\d+)/, '$3/$2/$1');
                        var startDate = new Date(beginOffer), endDate = new Date(beginEl);
                        if(startDate>=endDate) {
                            errorValue = true;
                            $scope.protocolForm.form[date1].$setValidity(date1, !errorValue);

                            if(rebiddingType == 'online') {
                                errorTitie = "Дата и время начала переторжки должны быть строго меньше даты и времени окончания приема откорректированных документов";
                            } else
                                errorTitie = "Дата начала подачи предложений должна быть строго меньше даты и времени окончания подачи предложений";
                            if(errorValue) $scope.protocolForm.form[date1].$setDirty();
                            $scope.protocolForm.form[date1].$$element[0].title = errorValue?errorTitie:"";
                        } else {
                            errorValue = false;
                            $scope.protocolForm.form[date0].$setValidity(date0, !errorValue);
                            $scope.protocolForm.form[date1].$setValidity(date1, !errorValue);
                        }
                    }
                    beginEl = $scope.protocolForm.form[date1];
                    if(!errorValue && beginEl && endEl && beginEl.$$element[0].value && endEl.$$element[0].value){
                        beginEl = beginEl.$$element[0].value.replace(/(\d+).(\d+).(\d+)/, '$3/$2/$1');
                        endEl = endEl.$$element[0].value.replace(/(\d+).(\d+).(\d+)/, '$3/$2/$1');
                        var startDate = new Date(beginEl), endDate = new Date(endEl);
                        if(rebiddingType && startDate>=endDate) {
                            errorValue = true;
                            $scope.protocolForm.form[date2].$setValidity(date2, !errorValue);
                            if(rebiddingType) {
                                errorTitie = "Дата и время рассмотрения предложений должны быть больше Даты и времени ";
                                errorTitie += (rebiddingType == 'online' ? "окончания приема откорректированных документов" : "окончания подачи предложений");
                            } else
                                errorTitie = "Дата и время подведения итогов процедуры должны быть больше или равны Дате и времени рассмотрения предложений";
                            if(errorValue) $scope.protocolForm.form[date2].$setDirty();
                            $scope.protocolForm.form[date2].$$element[0].title = errorValue?errorTitie:"";
                        } else {
                            errorValue = false;
                            $scope.protocolForm.form[date1].$setValidity(date1, !errorValue);
                            $scope.protocolForm.form[date2].$setValidity(date2, !errorValue);
                        }
                    }
                    endEl = $scope.protocolForm.form[date2];
                    if(!errorValue && endEl && resultEl && endEl.$$element[0].value && resultEl.$$element[0].value){
                        resultEl = resultEl.$$element[0].value.replace(/(\d+).(\d+).(\d+)/, '$3/$2/$1');
                        endEl = endEl.$$element[0].value.replace(/(\d+).(\d+).(\d+)/, '$3/$2/$1');
                        var endDate = new Date(endEl), resultDate = new Date(resultEl);
                        if(endDate>resultDate) {
                            errorValue = true;
                            $scope.protocolForm.form[date3].$setValidity(date3, !errorValue);
                            errorTitie = "Дата и время подведения итогов процедуры должны быть больше или равны Дате и времени рассмотрения предложений";
                            if(errorValue) $scope.protocolForm.form[date3].$setDirty();
                            $scope.protocolForm.form[date3].$$element[0].title = errorValue?errorTitie:"";
                        } else {
                            errorValue = false;
                            $scope.protocolForm.form[date2].$setValidity(date2, !errorValue);
                            $scope.protocolForm.form[date3].$setValidity(date3, !errorValue);
                        }
                    }

                    return errorValue;
                }

                /*
                * Имя причины по id
                * */
                function getProtocolClaimRejectReasonNameByIdFn(id) {
                    var result = '';
                    angular.forEach($scope.protocolClaimRejectReason, function (value) {
                        if(value.id === id) result = value.name;
                    });
                    return result;
                }

                /**
                 * Отображение сообщения о переводе закупки на этап "Работа комиссии" в ЛК ЕИС
                 */
                function showMessagePurchaseFn(model) {
                    if(model.procedure.type && model.procedure.type.id!=='simplecontest' && model.procedure.customer && model.procedure.customer.eisIntegration &&
                        !model.procedure.commercial && (!model.protocolData.state || model.protocolData.state.id !== "published")) {
                        return true;
                    }
                    else
                        return false;
                }

                function isDemoFn() {
                    return $rootScope.properties && $rootScope.properties['demo.mode']==="true";
                }
                
                $scope.onChangeRebiddingType = function (lot){
                    if(lot.rebiddingType==='offline' && lot.rebiddingBegins){
                        lot.rebiddingBegins = new Date(lot.rebiddingBegins).setHours(0,0,0,0);
                    }
                };
                $scope.isSimpleContest44 = function () {
                    return $scope.model.procedure.type.id === "simplecontest" && $scope.model.procedure.section==='44';
                };
                function changePriceFn(lot, prices) {
                    if($scope.isSimpleContest44()) {
                        if (isNaN(prices.contractSum) || prices.contractSum * 1===0) {
                            $scope.prices_error[lot] = 'Цена должна быть больше 0.';
                        } else {
                            delete $scope.prices_error[lot];
                        }
                    } else {
                        if (isNaN(prices.contractSum) || isNaN(prices.contractSumNoNDS) || prices.contractSumNoNDS * 1 === 0 || prices.contractSum * 1 === 0) {
                            $scope.prices_error[lot] = 'Цена должна быть больше 0.';
                        } else if (prices.contractSumNoNDS * 1 > prices.contractSum * 1) {
                            $scope.prices_error[lot] = 'Предложенная цена по лоту без НДС не должна превышать предложенную цену по лоту с НДС.';
                        } else {
                            delete $scope.prices_error[lot];
                        }
                    }
                }
                function checkPrices() {
                    $scope.model.protocolData.document.lots.protocolLotApplication.protocolLotApplications.forEach(function (lot) {
                        if (lot.application) {
                            lot.application.forEach(function (appl) {
                                if (appl && appl.contractSigned) {
                                    changePriceFn(lot.lotInfo.ordinalNumber, appl);
                                }
                            })
                        }
                    });
                }

                function isLotMultipleContracts(lot){
                    return ($scope.model.procedure.lots.find(l=>l.id===lot.lotInfo.id)||{}).multipleContracts;
                }

                $scope.hasPrices = function(lot, apply){
                    return $scope.participantPrices[lot] && $scope.participantPrices[lot].find(item=>item===apply.applicationNumber);
                }
                $scope.hasPriceInfo = function(){
                    return $scope.model.protocolData.document.purchase.procPriceType==='NO_PRICE' && $scope.model.protocolData.document.purchase.typeCode!=='prequalification';
                }
                $scope.getProtocolName = function(){
                    return $scope.typeProtocol==='REBIDDING_OPEN' && $scope.model ? $scope.model.protocolData.name : $scope.prn[$scope.typeProtocol];
                }
                $scope.signContractAttr = function(lot, apply) {
                    return showContractSignedLotFn(lot) && (isLotMultipleContracts(lot) && apply.accepted.code === 'T'
                        || ($scope.getAcceptedOnLot(lot)===1 && apply.accepted.code === 'T')
                        || (apply.winnerIndication?.code==='F' && $scope.getAcceptedOnLot(lot)>1));
                }
                /*
                 * Обязательность заключения договора для протокола
                 */
                $scope.signContractRequired = function(apply, lot, index) {
                    return (apply.winnerIndication?.code==='F' && !$scope.additionalRequirements(lot) || isAuctionInitSign() && apply.contractSigned && index===0) || (!isLotMultipleContracts(lot) && !$scope.additionalRequirements(lot));
                }
                /*
                UETP-9883 Для многолотовых процедур, если в протоколе конкурсной комиссии не установлен чек-бокс "Несостоявшаяся закупка"
                и хотя бы по одному из лотов ставится "Заключение договора", то для лотов, где допущена заявка только от одного Участника,
                поле "Заключение договора" делать необязательным и разрешать публиковать протокол без его заполнения
                 */
                $scope.additionalRequirements = (lot) => {
                    return ['REZ', 'CHANGE_CONTRACT_ADDITIONAL'].includes($scope.typeProtocol) && ['opencontest', 'requestquotations', 'limitedcontest', 'limitedrequestquotations',
                        'requestproposals', 'limitedrequestproposals','open2stagecontest'].includes($scope.model.protocolData.document.purchase.typeCode) &&
                        $scope.model.protocolData.document.lots?.protocolLotApplication?.protocolLotApplications?.length>1 &&
                        $scope.getAcceptedOnLot(lot)===1 && !!$scope.model.protocolData.document.lots?.protocolLotApplication?.protocolLotApplications?.find(l=>hasSignedContractFn(l));
                }

                function hasSignedContractFn(lot){
                    return lot.application.find(apply => apply.contractSigned === true);
                }

                $scope.signContractDisabled = function(apply, lot, index) {
                    const isMutilple = (isLotMultipleContracts(lot) && (apply.winnerIndication?.code==='F' || apply.contractSigned && index===0));
                    return isAuctionInitSign() && (isMutilple || (!isLotMultipleContracts(lot) && !($scope.typeProtocol === 'REZ' && $scope.isProtocolAcceptedEnabled(lot))));
                }
                $scope.winnerIndicationChange = function(apply, lot, level1Index){
                    apply.contractSigned = apply.winnerIndication?.code==='F' ? true : ''; $scope.setWinnerSelect(lot, level1Index);
                }
                $scope.checkDateTimeG = function(field1, field2) {
                    if (!(field1 && field2)) return true;
                    const fourHours  = 4 * 60 * 60 * 1000;
                    const field1Date = new Date(angular.copy(field1-fourHours)).setSeconds(0, 0), field2Date = new Date(angular.copy(field2)).setSeconds(0, 0);
                    const date1 = field1Date instanceof Date ? Date.parse(field1Date) : field1Date, date2 = field2Date instanceof Date ? Date.parse(field2Date) : field2Date;
                    return date1>date2;
                }
                $scope.getProcedureSize = () => {
                    return $scope.model.protocolData.archiveSizes?.procedure?.procedure;
                }
                $scope.getLotSize = (lotId) => {
                    return $scope.model.protocolData.archiveSizes && $scope.model.protocolData.archiveSizes[lotId] ? $scope.model.protocolData.archiveSizes[lotId]['lot'] : undefined;
                }
                $scope.getClaimSize = (lotId, claimNumber) => {
                    return $scope.model.protocolData.archiveSizes && $scope.model.protocolData.archiveSizes[lotId] && $scope.model.protocolData.archiveSizes[lotId][claimNumber] ? `Суммарный объем вложений по заявке: ${$scope.model.protocolData.archiveSizes[lotId][claimNumber]}` : undefined;
                }
                $scope.hasMchd = (lotId, applicationNumber) => {
                    return $scope.model.protocolData.mchds && $scope.model.protocolData.mchds[lotId] && $scope.model.protocolData.mchds[lotId][applicationNumber];
                }
                $scope.getMchdClaimId = (lotId, applicationNumber) => {
                    return $scope.model.protocolData.mchds[lotId][applicationNumber].mchdId;
                }
                $scope.getMchdClaimState = (lotId, applicationNumber) => {
                    return $scope.model.protocolData.mchds[lotId][applicationNumber].state;
                }
                $scope.getMchdStateDesc = (lotId, applicationNumber) => {
                    const code = $scope.model.protocolData.mchds[lotId][applicationNumber].state;
                    if(code==='valid')
                        return 'Доверенность действует на момент подписания документа';
                    else if(code==='expired')
                        return 'Срок действия доверенности истек на момент подписания документа';
                    else if(code==='revoked')
                        return 'Доверенность отозвана на момент подписания документа';
                    else if(code==='terminated')
                        return 'Действие доверенности прекращено по событию ГК РФ на момент подписания документа';
                    else if(code==='not valid')
                        return 'Срок действия доверенности еще не наступил на момент подписания документа';
                    else if(code==='undefined')
                        return 'Ожидается результат проверки статуса доверенности от информационной системы хранения';
                }
                $scope.getMchdLink = (lotId, applicationNumber) => {
                    return $scope.mchdUrl + 'full?poanum=' + $scope.model.protocolData.mchds[lotId][applicationNumber].mchdId +
                        '&principal=' + $scope.model.protocolData.mchds[lotId][applicationNumber].userId + '&agent=' +
                        $scope.model.protocolData.mchds[lotId][applicationNumber].agentInn + '&check=' + $scope.model.protocolData.mchds[lotId][applicationNumber].stateDesc;
                }
                $scope.getProtocolAcceptedSet = (selectDataAccepted, lot) => {
                    return ['opencontest','limitedcontest','requestquotations','limitedrequestquotations','requestproposals','limitedrequestproposals','open2stagecontest','openauction','limitedauction'].includes($scope.procType) &&
                        ['admincab', 'manager', 'organizer'].includes($scope.getCabType()) &&
                        ['223','commercial'].includes($scope.model.procedure.section) &&
                        ['NO_PRICE', 'MAX_PRICE'].includes($scope.model.protocolData.document.purchase.procPriceType) &&
                        !$scope.isCancelLot(lot) && lot.application.length===1 && !$scope.hasPart1Claim(lot)
                            ? selectDataAccepted : selectDataAccepted.filter(i=>i.code!=='N');

                }
                // Если текущий протокол REBIDDING_PART1 и есть одна заявка на первый этап и одна на переторжку
                $scope.hasPart1Claim = (lot) => {
                    return 'REBIDDING_PART1' === $scope.model.protocolData.type &&
                        (lot.application.filter(appl => $scope.model.protocolData?.lotStage1Claims[lot.lotInfo.ordinalNumber]?.includes(Number(appl.applicationNumber))) || []).length===1;
                }
                $scope.isProtocolAcceptedEnabled = (lot) => {
                    return ['opencontest','limitedcontest','requestquotations','limitedrequestquotations','requestproposals',
                        'limitedrequestproposals','open2stagecontest'].includes($scope.procType) &&
                        ['admincab', 'manager', 'organizer'].includes($scope.getCabType()) &&
                        ['223','commercial'].includes($scope.model.procedure.section) &&
                        ['NO_PRICE', 'MAX_PRICE'].includes($scope.model.protocolData.document.purchase.procPriceType) &&
                        !$scope.isCancelLot(lot) && lot.application.length===1 && 'REZ'===$scope.typeProtocol &&
                        !$scope.model.procedure.protocols.some(p=>['PART1', 'PARTS'].includes(p.type.id) && 'published'===p.state.id) &&
                        ['opening','ratingFirstClaimParts','finished'].includes($scope.model.procedure.state.id);
                }
                $scope.isAuctionProtocolAcceptedEnabled = (lot) => {
                    return ['openauction','limitedauction'].includes($scope.procType) &&
                        'organizer'===$scope.getCabType() && !$scope.isView &&
                        ['223','commercial'].includes($scope.model.procedure.section) &&
                        ['NO_PRICE', 'MAX_PRICE'].includes($scope.model.protocolData.document.purchase.procPriceType) &&
                        !$scope.isCancelLot(lot) && lot.application.length===1 && 'REZ'===$scope.typeProtocol &&
                        !$scope.model.procedure.protocols.some(p=>['PART1', 'PARTS'].includes(p.type.id) && 'published'===p.state.id) &&
                        $scope.model.procedure.state.id==='ratingFirstClaimParts';
                }
                $scope.hasNAdmittanceInParts = (appl, lot) => {
                    return ['opencontest','limitedcontest','requestquotations','limitedrequestquotations','requestproposals',
                            'limitedrequestproposals','open2stagecontest','openauction','limitedauction'].includes($scope.procType) &&
                            ['admincab', 'manager', 'organizer'].includes($scope.getCabType()) &&
                            ['223','commercial'].includes($scope.model.procedure.section) &&
                            ['NO_PRICE', 'MAX_PRICE'].includes($scope.model.protocolData.document.purchase.procPriceType) &&
                            !$scope.isCancelLot(lot) && lot.application.length===1 && 'REZ'===$scope.typeProtocol &&
                            $scope.model.procedure.protocols.some(p=>['PART1', 'PARTS'].includes(p.type.id) && 'published'===p.state.id) &&
                            ['summarizing'].includes($scope.model.procedure.state.id) &&
                            appl.accepted.code === 'N';
                }
                $scope.isAcceptNotSet = (lot) => lot.application.length===1 && lot.application[0].accepted.code === 'N';
                $scope.showProtocolFields = () => {
                    return ['TRANSFER','COMPLAINT','CUSTOM'].includes($scope.typeProtocol) || ($scope.hasSecondStage() && ['requestproposals', 'limitedrequestproposals', 'open2stagecontest'].includes($scope.model.protocolData.document.purchase.typeCode) &&
                        $scope.protf.claimEndsSecondStage && $scope.typeProtocol === 'PART1' && $scope.stageProtocol !== 'stage2');
                }
                // hasBids должен быть только когда допущено >1 участника и подано одно ЦП
                $scope.hasBids = (appl) => {
                    return appl?.some(appl=>appl.hasBids);
                }

                $scope.hasLotBids = () => !($scope.typeProtocol === 'REZ' && $scope.model.protocolData.document.lots.protocolLotApplication.protocolLotApplications.every(lot=>lot.lotInfo.hasBids===false));

                /*
                 у заказчика установлен признак "Включать в протокол проведения аукциона все допущенные заявки",
                 по лоту допущено больше одного участника и все допущенные участники подавали на торгах ценовые предложения
                 */
                $scope.auctionRezProtocolIncludeAllCondition = (lot) => {
                    return $scope.model.procedure.customer.auctionRezProtocolIncludeAll && lot.application && $scope.getAcceptedOnLot(lot)>1 && lot.application?.every(appl=>appl.hasBids);
                }

                $scope.showPhrase1 = (lot) => {
                    return !$scope.isCancelLot(lot) && (lot.application != null && lot.application.length>0 && (lot.lotInfo.hasBids===false || $scope.hasBids(lot.application))) && $scope.typeProtocol === 'REZ' && !$scope.auctionRezProtocolIncludeAllCondition(lot);
                }

                $scope.downloadProtocolDocs = () => {
                    protocolSrv.downloadProtocolDocsById($stateParams);
                };
                $scope.acceptDisabled = (apply, lot) => $scope.isView || $scope.getAdditionalDisableAccepted(apply) && !$scope.isProtocolAcceptedEnabled(lot) || $scope.hasNAdmittanceInParts(apply, lot);
                $scope.hasLotSuperiorityNR = (lot) => lot.application != null && lot.application.length>0 && lot.application.some(appl=> appl.percentOfferSuperiority || appl.contractSumSuperiorityNoNDS);
                $scope.aucRezDocx = (lot) => $scope.typeProtocol === 'REZ' && $scope.isShowAuctionDownloadDocxBtn && lot.lotInfo.superiorityNationalRegime===true && $scope.hasLotSuperiorityNR(lot) && !$scope.isCancelLot(lot) && lot.application != null && lot.application.length>0
            }]
    });