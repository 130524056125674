/**
 * @author dmitrys
 */
angular.module('uetp').service('noticeSrv', ['$state', 'httpSrv', 'desktopSrv', '$httpParamSerializerJQLike', 'proceduresSrv',
    '$stateParams', 'systemMessages', '$rootScope', '$filter',
    function ($state, httpSrv, desktopSrv, $httpParamSerializerJQLike, proceduresSrv, $stateParams, systemMessages, $rootScope, $filter) {
        var service = this;

        /**
         * Информация по извещению
         */
        var noticeInfo = {};
        /*
         * Активная вкладка
         */
        service.showPanel = 'procedure';
        service.state = 'procedure';
        /**
         * Форма редактирования или создания
         */
        service.isEdit = true;
        /**
         * Признак, что изменили вложение
         */
        service.isAttachEdit = false;
        /**
         * Признак что форму открыли на просмотр
         * @type {boolean}
         */
        service.isReadOnly = false;
        service.config = {};
        /**
         * Формы вкладок (компонентов)
         */
        service.form = {
            procedure: {}
        };
        var submitted = false;
        /**
         * Объект для передачи функции фокуса
         */
        service.focusElement = {};
        /*
        * Выбранный заказчик
        * */
        service.selectedCustomer = {};

        service.showExtendFields = true;

        service.noticeExtFields = true;

        service.showCustomerEISError = false;

        service.noticeIntegrationEIS = true;

        service.initNoticeIntegrationEIS = true;

        service.customerLoading = false;

        var procedureInfo = {
            opencontest: "Открытый конкурс",
            open2stagecontest: "Открытый двухэтапный конкурс",
            openauction: "Открытый аукцион",
            saleauction: "Доходный аукцион",
            requestquotations: "Запрос котировок",
            requestquotationssmb: "Запрос котировок (МСП)",
            offerplacement: "Размещение оферты",
            prequalification: "Предварительный квалификационный отбор",
            limitedcontest: "Конкурс с ограниченным участием",
            limitedauction: "Аукцион с ограниченным участием",
            limitedrequestquotations: "Запрос котировок с ограниченным участием",
            requestproposals: "Запрос предложений",
            limitedrequestproposals: "Запрос предложений с ограниченным участием",
            requestproposalssmb: "Запрос предложений (МСП)",
            limitedcontestqualification: "Конкурс среди организаций, прошедших предварительный квалификационный отбор", 
            auctionsmb: "Аукцион (МСП)",
            unlimitedprequalification: "Предварительный квалификационный отбор с неограниченным сроком",
            simplecontest: "Закупка малого объема",
            openpublicoffer: "Открытое публичное предложение",
            requestprices: "Запрос цен"
        };


        service.getShowPanel = getShowPanelFn;
        service.setShowPanel = setShowPanelFn;
        service.defaultModel = defaultModelFn;
        service.defaultLot = defaultLotFn;
        service.defaultNomenclature = defaultNomenclatureFn;
        service.setState = setStateFn;
        service.getState = getStateFn;
        service.getProcedure = getProcedureFn;
        service.setEditForm = setEditFormFn;
        service.getReadOnlyForm = getReadOnlyFormFn;
        service.setReadOnlyForm = setReadOnlyFormFn;
        service.getEditForm = getEditFormFn;
        service.publishProc = publishProcFn;
        service.deleteProc = deleteProcFn;
        service.saveProc = saveProcFn;
        service.getNoticeInfo = getNoticeInfoFn;
        service.setNoticeInfo = setNoticeInfoFn;
        service.initProcNameByType = initProcNameByTypeFn;
        service.getForm = getFormFn;
        service.setForm = setFormFn;
        service.isSubmitted = isSubmittedFn;
        service.setSubmitted = setSubmittedFn;
        service.setFocusFirstInvalidFun = setFocusFirstInvalidFunFn;
        service.getDataForSign = getDataForSignFn;
        service.sendSignData = sendSignDataFn;
        service.getDataOrgCustomer = getDataOrgCustomerFn;
        service.getDataOrgCustomerBySection = getDataOrgCustomerBySectionFn;
        service.getDataOrgHeadDzoCustomer = getDataOrgHeadDzoCustomerFn;
        service.getDataOrgHeadDzoCustomerBySection = getDataOrgHeadDzoCustomerBySectionFn;
        service.getHeadDzoCustomersByNotice = getHeadDzoCustomersByNoticeFn;
        service.getCustomerByInn = getCustomerByInnFn;
        service.setAttachments = setAttachmentsFn;
        service.getDataForSignParticipantsDoc = getDataForSignParticipantsDocFn;
        service.sendSignDataParticipantsDoc = sendSignDataParticipantsDocFn;
        service.checkDate = checkDateFn;
        service.doUnlimitedCheckDate = doUnlimitedCheckDate;
        service.englishSymbol = englishSymbolFn;
        service.downloadDocArchive = downloadDocArchiveFn;
        service.downloadDoc = downloadDocFn;
        service.addTime = addTimeFn;
        service.setAttachEdit = setAttachEditFn;
        service.getAttachEdit = getAttachEditFn;
        service.isNew223 = isNew223Fn;
        service.isNew44 = isNew44Fn;
        service.isNewCommercial = isNewCommercialFn;
        service.isInitedNotice = isInitedNoticeFn;
        service.hasPriceInNotice = hasPriceInNoticeFn;
        service.setSelectedCustomer = setSelectedCustomerFn;
        service.getSelectedCustomer = getSelectedCustomerFn;
        service.loadPlanInfo = loadPlanInfoFn;
        service.isShowExtendedFields = isShowExtendedFieldsFn;
        service.setShowExtendedFields = setShowExtendedFieldsFn;
        service.isNoticeExtFields = isNoticeExtFieldsFn;
        service.setNoticeExtFields = setNoticeExtFieldsFn;
        service.isNoticeShowCustomerEISError = isNoticeShowCustomerEISErrorFn;
        service.isCustomerLoading = isCustomerLoadingFn;
        service.setCustomerLoading = setCustomerLoadingFn;
        service.setNoticeShowCustomerEISError = setNoticeShowCustomerEISErrorFn;
        service.isNoticeIntegrationEIS = isNoticeIntegrationEISFn;
        service.setNoticeIntegrationEIS = setNoticeIntegrationEISFn;
        service.isInitNoticeIntegrationEIS = isInitNoticeIntegrationEISFn;
        service.setInitNoticeIntegrationEIS = setInitNoticeIntegrationEISFn;
        service.getSectionName = getSectionNameFn;
        service.getSection = getSectionFn;
        service.getInitContractSum = getInitContractSumFn;
        service.getInitDepositNeeded = getInitDepositNeededFn;
        service.getOrgRequisites = getOrgRequisitesFn;
        service.isExtFieldNotice = isExtFieldNoticeFn;
        service.isOpenPublicOffer = isOpenPublicOfferFn;
        service.isSimpleContest = isSimpleContestFn;
        service.isRequestPrices = isRequestPricesFn;
        service.isSimpleContest44 = isSimpleContest44Fn;
        service.hasPriceMode = hasPriceModeFn;
        service.isPercentPriceMode = isPercentPriceModeFn;
        service.updatePriceMode = updatePriceModeFn;
        service.showApplicationSupply = showApplicationSupplyFn;
        service.getWinnerPay = getWinnerPayFn;
        /**
         * Значения модели процедуры по умолчанию
         */
        function defaultModelFn(type, claimBegins) {
            var user = JSON.parse(localStorage.getItem("userData"));
            return {
                "russianPriority": false,
                "msp": false,
                "claimBegins":  claimBegins,
                "claimEnds": null,
                "priceType": "MAX_PRICE",
                "price": null,
                "priceNoNDS": null,
                "lots": {
                    "lot": []
                },
                "attachment": [],
                "version": 0,
                "number": "",
                "name": "",
                "customerCode": "",
                "customerName": "",
                "organizerCode": user.code,
                "organizerName": user.fullName,
                "organizerNotificationControl": user.notificationControl,
                "typeCode": type,
                "typeName": initProcNameByTypeFn(type),
                "consentProcessing": systemMessages.CONSENT_PROCESSING,
                "consentDissemination": systemMessages.CONSENT_DISSEMINATION,
                "extendFields":null
            }
        }

        /*
        Настройки чек-боксов для номенклатуры по нац. режиму
         */
        service.defaultNRConfig = {
            "1" : {
                prohibitionNationalRegime: true,
                exceptionNationalRegime: false
            },
            "2": {
                constraintNationalRegime: true,
                exceptionNationalRegime: false
            },
            "3": {
                prohibitionNationalRegime: true,
                constraintNationalRegime: false,
                exceptionNationalRegime: false,
            }
        }

        service.booleanDefined = (value) => [true,false].includes(value);

        /**
         * Значения лота по умолчанию
         */
        function defaultLotFn() {
            return {
                alternativeOffers:0,
                lotItems:{
                    lotItem:[]
                },
                subject: "",
                currency: {
                    "code": "RUB",
                    "digitalCode": 643,
                    "name": "Российский рубль"
                },
                contractSumNoNDS: null,
                contractSum: null
            }
        }

        /**
         * Значение номерклатуры по умолчанию
         */
        function defaultNomenclatureFn() {
            return {
                name: "",
                deliveryPlace: null,
                qty: null
            }
        }

        function getShowPanelFn() {
            return service.showPanel;
        }

        function setShowPanelFn(type) {
            service.showPanel = type;
        }

        /**
         * Установить активное состояние вкладки
         * @param value
         */
        function setStateFn(value) {
            service.state = value;
        }

        /**
         * Вернуть активное состояние вкладки
         */
        function getStateFn() {
            return service.state;
        }

        /**
         * Получение процедуры
         * @param type - тип процедуры
         * @param id - id процедуры
         */
        async function getProcedureFn(type, id) {
            let procData = await httpSrv.http({
                method:'GET',
                url: type+ '/notices/'+id
            });
            setNoticeInfoFn(procData.data.result);
            return procData;
        }
        /**
         * Получить конфигурация процедуры для просмотра
         */
        function getReadOnlyFormFn() {
            return service.isReadOnly;
        }
        /**
         * Установить конфигурацию процедуры  для просмотра
         * @param isReadOnly - форма просмотра
         */
        function setReadOnlyFormFn(isReadOnly) {
            service.isReadOnly = isReadOnly;
        }

        /**
         * Получить конфигурация процедуры
         */
        function getEditFormFn() {
            return service.isEdit;
        }

        /**
         * Установить конфигурацию процедуры
         * @param isEdit - форма редактирования или создания
         */
        function setEditFormFn(isEdit) {
            service.isEdit = isEdit;
        }

        function publishProcFn(model) {
            return httpSrv.http({
                url: '/' + model.type.id + '/' + model.id + '/publish'
            })
        }
        function deleteProcFn(model) {
            return httpSrv.http({
                url: '/' + model.type.id +'/notices/' + model.id,
                method: 'DELETE'
            })
        }

        /**
         * Сохранить или обновить процедуру
         * @param model
         */
        function saveProcFn(model, participantsDoc) {
            //сохранить
            var method = 'PUT';
            //обновить
            if (model.id) {
                method = 'POST';
            }
            var data = noticeInfo;
            var extendFields = noticeInfo.document.extendFields;
            var templateVersion = noticeInfo.document.templateVersion;
            if(isNew223Fn() && model && model.typeCode !=='simplecontest') {
                if (model.customerBranch) {
                    data.customerBranch = model.customerBranch;
                    delete model.customerBranch;
                } else
                    delete data.customerBranch;
            }
            if(model.showpricends) {
                data.showpricends = model.showpricends;
                delete model.showpricends;
            }
            noticeInfo.document = model;
            noticeInfo.document.extendFields = extendFields;
            if(isNoticeIntegrationEISFn())
                noticeInfo.document.templateVersion = templateVersion;
            
            if(participantsDoc){
            	noticeInfo.participantsDoc = {
            			document: participantsDoc};
            }
            if(isNew223Fn())
                noticeInfo.document.schemaVersion = 1;
            return httpSrv.http({
                url: '/'+noticeInfo.document.typeCode+'/notices',
                method:method,
                data: data
            })
        }

        function setNoticeInfoFn(value) {
            noticeInfo = value;
        }
        function getNoticeInfoFn() {
            return noticeInfo;
        }
        /**
         * Имя процедуры по типу
         * @param type - тип процедуры
         */
        function initProcNameByTypeFn(type) {
            return procedureInfo[type];
        }

        /**
         * Получить форму по состоянию
         * @param state - состояние
         */
        function getFormFn(state) {
            return service.form[state];
        }
        function setFormFn(state,form) {
            service.form[state] = form;

        }
        service.focusFirstInvalid  = function(data) {
            if (typeof service.focusElement === "function"){
                service.focusElement(data);
            }
        };

        function setFocusFirstInvalidFunFn(fun) {
            service.focusElement = fun;
        }
        /**
         * Получение данных для подписи
         * @param procType - тип процедуры
         * @param noticeId - идентификатор извещения
         * @returns {*}
         */
        function getDataForSignFn(procType, noticeId) {
            return httpSrv.http({
                url: '/' + procType + '/notices/' + noticeId  + '/getDataForSign'
            })
        }
        /**
         * Отправка документа
         * @param procType - тип процедуры
         * @param noticeId - идентификатор извещения
         * @param data - данные для отправка с подписью
         * @returns {*}
         */
        function sendSignDataFn (procType, noticeId, data) {
            return httpSrv.http({
                url: '/' + procType + '/notices/' + noticeId  + '/publish',
                data: data
            })
        }
        /**
         * Получение данных для подписи документа участников
         * @param procType - тип процедуры
         * @param noticeId - идентификатор извещения
         * @returns {*}
         */
        function getDataForSignParticipantsDocFn(procType, noticeId) {
            return httpSrv.http({
                url: '/' + procType + '/notices/' + noticeId  + '/getDataForSignParticipantsDoc'
            })
        }
        /**
         * Отправка документа участников
         * @param procType - тип процедуры
         * @param noticeId - идентификатор извещения
         * @param data - данные для отправка с подписью
         * @returns {*}
         */
        function sendSignDataParticipantsDocFn (procType, noticeId, data) {
            return httpSrv.http({
                url: '/' + procType + '/notices/' + noticeId  + '/storeParticipantsDocSignature',
                data: data
            })
        }
        /**
         * Получение списка назначенных организатору заказчиков
         */
        function getDataOrgCustomerFn () {
            return httpSrv.http({
                method: 'GET',
                url: '/organizer/customers/getMyCustomers'
            })
        }
        function getDataOrgCustomerBySectionFn (section, convention) {
            let path = `/organizer/customers/getMyCustomers/${section}`;
            if(!!convention) path += `/${convention}`
            return httpSrv.http({
                method: 'GET',
                url: path
            })
        }
        /**
         * Получение фильтрованного списка назначенных организатору заказчиков
         */
        function getDataOrgHeadDzoCustomerFn () {
            return httpSrv.http({
                method: 'GET',
                url: '/organizer/customers/getMyHeadDzoCustomers'
            })
        }
        function getDataOrgHeadDzoCustomerBySectionFn(section, convention){
            let path = `/organizer/customers/getMyHeadDzoCustomers/${section}`;
            if(!!convention) path += `/${convention}`
            
            return httpSrv.http({
                method: 'GET',
                url: path
            })
        }
        function getHeadDzoCustomersByNoticeFn(section, noticeId){
            return httpSrv.http({
                method: 'GET',
                url: '/organizer/customers/getHeadDzoCustomersByNotice/' + section + '/' + noticeId,
            })
        }
        function getCustomerByInnFn (inn) {
            return httpSrv.http({
                method: 'GET',
                url: '/organizer/customers/getCustomerByInn/'+inn
            })
        }
        function setAttachmentsFn(attachment) {
            if(noticeInfo && noticeInfo.document){
                noticeInfo.document.attachment = attachment;
            }
        }
        function checkDateFn(form, start, ends, errorField, dependedForm, dependedField) {
            var stop;
            for(var i=0;i<ends.length;i++){
                stop = doCheckDate(form, start, ends[i].auctionDateStart,errorField,dependedForm,dependedField);
                if(stop) break;
            }
            return stop;
        }
        function doCheckDate(form, start, end, errorField, dependedForm, dependedField) {
            var errorTitie = "" , errorValue = false;
            var startDate = new Date(start);
            var endDate = new Date(end);
            if(end){
                if(start){
                    if(startDate>endDate){
                        errorValue = true;
                        errorTitie = "Дата и время начала торгов должны быть позже даты и времени окончания подачи заявок";
                    }else{
                        errorValue = false;
                        errorTitie = "";
                    }
                }
            }else{
                errorValue = false;
                errorTitie = "";
            }
            form[errorField].$setValidity("endDate", !errorValue);
            if(dependedField) dependedForm[dependedField].$setValidity("endDate", !errorValue);
            if(errorValue) form[errorField].$setDirty();
            form[errorField].$$element[0].title = errorValue?errorTitie:"";
            return errorValue;
        }
        function doUnlimitedCheckDate(form, start, end, entered, errorField) {
            var errorTitie = "" , errorValue = false;
            var startDate = new Date(start), endDate = new Date(end), enteredDate = new Date(entered);
            if(end){
                if(start){
                    if(enteredDate>endDate) {
                        errorValue = true;
                        errorTitie = "Дата и время следующего вскрытия должны быть меньше даты и времени последнего вскрытия";
                    } else if(enteredDate<startDate){
                        errorValue = true;
                        errorTitie = "Дата и время следующего вскрытия не должны быть меньше текущей даты и времени";
                    } else {
                        errorValue = false;
                        errorTitie = "";
                    }
                }
            }else{
                errorValue = false;
                errorTitie = "";
            }
            form[errorField].$setValidity("submissionClosePKODateTime", !errorValue);
            if(errorValue) form[errorField].$setDirty();
            form[errorField].$$element[0].title = errorValue?errorTitie:"";
            return errorValue;
        }

        function englishSymbolFn(form,field){
            var re = /[a-zA-Z]/,
                fval = form[field].$$element[0].value;
            var parts = fval.split(" ");
            var result = "в поле присутствуют английские буквы:\n";
            var isCheck = true;

            for (var i=0; i<parts.length; i++) {

                var res = parts[i].match(re);
                if (res != null)  {
                    result = result + parts[i] + "\n";
                    isCheck = false;
                }
            }

            if (!isCheck) {
                markInvalid(form,field,result,true);
            }
            else{
                markInvalid(form,field,false,false);
            }
        }
        function markInvalid(form,field,result,value) {
            if(value) form[field].$setDirty();
            form[field].$$element[0].title = value?result:"";
        }
        function downloadDocArchiveFn(type, id){
            httpSrv.downloadFile('fs/file/' + type+ '/notices/'+id+'/downloadDocArchive');
        }
        function downloadDocFn(type,procId, id, fileName){
            httpSrv.downloadFile('fs/file/' + type+'/'+procId+ '/files/'+id,fileName);
        }
        function addTimeFn(date, value) {
           return new Date(date).setUTCHours(new Date(date).getUTCHours()+value, 0)
        }
        function setAttachEditFn(value) {
            service.isAttachEdit = value;
        }
        function getAttachEditFn() {
            return service.isAttachEdit;
        }
        function isSubmittedFn(){
            return {value:submitted};
        }
        function setSubmittedFn(value){
            submitted = value;
        }
        function isNew223Fn(){
            return $state?.$current?.name==='createNotice' || $state?.$current?.name==='createInitedNotice' && noticeInfo?.document?.section==='223' ||
                   noticeInfo?.document?.schemaVersion===1 && noticeInfo?.document?.section !=='44';
        }

        function isNew44Fn(){
            return $state?.$current?.name==='createNotice44' || $state?.$current?.name==='createInitedNotice' && noticeInfo?.document?.section==='44' ||
                   noticeInfo?.document?.section==='44'
        }
        
        function isNewCommercialFn(){
            return ($state?.$current?.name === 'createCommercialNotice' || $state?.$current?.name==='createInitedNotice' && noticeInfo?.document?.section==='commercial' ||
                   noticeInfo?.document?.section==='commercial');
        }

        // Только для формы просмотра
        function isExtFieldNoticeFn() {
            return noticeInfo.document && angular.isDefined(noticeInfo.document.purchaseMethodCode) && angular.isDefined(noticeInfo.document.purchaseCodeName) &&
                noticeInfo.document.purchaseMethodCode!=null && noticeInfo.document.purchaseCodeName!=null;
        }

        function isInitedNoticeFn(){
            if($state && $state.$current && $state.$current.name && $state.$current.name==='createInitedNotice')
                return true;
            else return false;
        }

        function hasPriceInNoticeFn(){
            return noticeInfo.document && (noticeInfo.document.priceType==='MIN_PRICE' || noticeInfo.document.priceType==='MAX_PRICE');
        }
        function setSelectedCustomerFn(value) {
            service.selectedCustomer = value;
        }
        function getSelectedCustomerFn() {
            return service.selectedCustomer;
        }
        /*
        * Загрузить информацию из плана
        * */
        function loadPlanInfoFn($scope,param) {
            return proceduresSrv.searchPurchases($scope, param);
        }

        function isShowExtendedFieldsFn() {
            return !(isNew223Fn() || isInitedNoticeFn()) ? false : service.showExtendFields;
        }

        function setShowExtendedFieldsFn(value){
            service.showExtendFields = value;
        }
 
        function getSectionNameFn() {
            return isNew44Fn() ? '44-ФЗ' : (isNewCommercialFn() ? 'Коммерческие торги' : '223-ФЗ');
        }
        
        function getSectionFn() {
            return isNew44Fn() ? '44' : (isNewCommercialFn() ? 'commercial' : '223');
        }
        
        function getInitContractSumFn(procType, noticeId){
        	return httpSrv.http({
                method: 'GET',
                url: '/' + procType + '/getInitContractSum/' + noticeId
            })
        }
        function getInitDepositNeededFn(procType, noticeId){
        	return httpSrv.http({
                method: 'GET',
                url: '/' + procType + '/getInitDepositNeeded/' + noticeId
            })
        }
        function isNoticeExtFieldsFn(){
            return service.noticeExtFields;
        }
        function setNoticeExtFieldsFn(value){
            service.noticeExtFields = value;
        }
        function isNoticeShowCustomerEISErrorFn(){
            return (isNew223Fn() || isInitedNoticeFn()) && service.showCustomerEISError;
        }
        function setNoticeShowCustomerEISErrorFn(value){
            service.showCustomerEISError = value;
        }
        function setCustomerLoadingFn(value){
            service.customerLoading = value;
        }
        function isCustomerLoadingFn(){
            return service.customerLoading;
        }
        function isNoticeIntegrationEISFn(){
            return service.noticeIntegrationEIS;
        }
        function setNoticeIntegrationEISFn(value){
            service.noticeIntegrationEIS = value;
        }
        function isInitNoticeIntegrationEISFn(){
            return service.initNoticeIntegrationEIS;
        }
        function setInitNoticeIntegrationEISFn(value){
            service.initNoticeIntegrationEIS = value;
        }
        
        function getOrgRequisitesFn(typeOrg, idOrg) {
            return httpSrv.http({
                method: 'GET',
                url: '/auth/org/requisites/' + typeOrg + '/' + idOrg
            })
        }

        function isOpenPublicOfferFn() {
            return $stateParams.type === 'openpublicoffer';
        }
        function isSimpleContestFn() {
            return $stateParams.type === 'simplecontest';
        }
        function isRequestPricesFn() {
            return $stateParams.type === 'requestprices';
        }
        function isSimpleContest44Fn() {
            return isSimpleContestFn() && isNew44Fn();
        }
        //Для всех процедур кроме ПКО, ПКОсНС, РО, ЗМО, КСОППКО
        function hasPriceModeFn(){
            return !['prequalification', 'unlimitedprequalification', 'offerplacement', 'simplecontest', 'limitedcontestqualification'].includes($stateParams.type);
        }
        function updatePriceModeFn(priceMode){
            if(hasPriceModeFn() && noticeInfo.document)
                noticeInfo.document.priceMode = priceMode;
        }
        function isPercentPriceModeFn(){
            return hasPriceModeFn() && noticeInfo.document && noticeInfo.document.priceMode==='percent';
        }

        /**
         * Отображать ли раздел "Требование к наличию обеспечения заявки"
         */
        function showApplicationSupplyFn() {
            return !['simplecontest', 'prequalification', 'unlimitedprequalification', 'saleauction', 'openpublicoffer', 'requestprices'].includes($stateParams.type)
                && noticeInfo.document.priceType!=='MIN_PRICE';
        }
        /**
         * Получение значения платы победителя
         * @param rubprice - значение цены
         * @param hasPrice - признак закупки с ценой
         * @returns {*}
         */
        function getWinnerPayFn(rubprice, hasPrice) {
            return httpSrv.http({
                method: 'POST',
                data: rubprice ?''+rubprice : null,
                url: `/auth/tariff/getWinnerPayment/${hasPrice}`
            })
        }

        /**
         * Проверка наличия okpd2 в перечнях (нац.режим)
         * @param okpd2 okpd2
         * @returns {*}
         */
        service.checkOkpd2InNationalRegime = (proctype, okpd2) => {
            return httpSrv.http({
                method: 'GET',
                url: `/${proctype}/checkNationalRegime/${okpd2}`
            })
        }
        service.checkOkpd2ListInNationalRegime = (proctype, okpd2List) => {
            return httpSrv.http({
                method: 'POST',
                data: okpd2List,
                url: `/${proctype}/checkNationalRegimeList`
            })
        }
        service.checkNationalRegime = (date2) => {
            if(!date2) {
                date2 = getServerTimeMsk();
            }

            if(date2) {
                let date1 =
                    $rootScope.properties['nationalRegime.date'] ? Date.parse($rootScope.properties['nationalRegime.date']) : null;
                if(date1) {
                    date1 = new Date(date1).setHours(0, 0, 0, 0);
                    if (date1 != null && date1 <= date2) {
                        return true;
                    }
                }
            }
            return false;
        }
    }]);
