angular.module('uetp').service('propertiesService', ['httpSrv', '$rootScope',
    function (httpSrv, $rootScope) {
        var properties = {
            "common": ['otherProcedures.url']
        };

        this.loadProperties = loadPropertiesFn;
        this.getProperty = getPropertyFn;

        function loadPropertiesFn() {
            var type = "common";
            if (!$rootScope.properties) $rootScope.properties = {};
            properties[type].forEach(function (propName) {
                if (!($rootScope.properties[propName] && $rootScope.properties[propName].length > 0)) loadProperty(propName);
            })
        }

        function loadProperty(propName) {
            httpSrv.http({
                method: 'GET',
                url: '/site/properties/property/' + propName
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $rootScope.properties[propName] = response.data.result;
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }
        function getPropertyFn(propName, onSuccess) {
            return new Promise(function (resolve, reject) {
                if($rootScope.properties && $rootScope.properties[propName]) return resolve($rootScope.properties[propName]);
                return httpSrv.http({
                    method: 'GET',
                    url: '/site/properties/property/' + propName
                }).then(function successCallback(response) {
                    if (response.data && response.data.success) {
                    	if (!$rootScope.properties) $rootScope.properties = {};
                        $rootScope.properties[propName] = response.data.result;
                        resolve(response.data.result)
                    }else{
                        reject(response);
                    }
                    if (onSuccess) onSuccess();
                }, function errorCallback(response) {
                    console.log(response);
                    reject(response);
                });
            });
        }

        this.loadPropertyList = (propNameList, onSuccess) => {
            let props = [];
            if (!$rootScope.properties) $rootScope.properties = {};

            const propKeys = Object.keys($rootScope.properties);
            propNameList.filter(key => !propKeys.includes(key)).forEach((key) => {
                props.push(key);
            });
            return httpSrv.http({
                method: 'POST',
                data: props,
                url: `/site/properties/propertyList`
            }).then(function successCallback(response) {
                if (response.data && response.data.success && response.data.result) {
                    angular.forEach(response.data.result, (el) => {
                        $rootScope.properties[el.id] = el.param;
                    });
                }
                if (onSuccess) onSuccess();
            }, function errorCallback(response) {
                console.log(response);
            });
        }

    }]);


