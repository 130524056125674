/**
 * @author sergeyu
 * Отпрака логов на сервер, там где отправка не предусмотрена.
 */
angular.module('uetp').directive("loggerUi", ['$parse', 'loggerUiSrv',
    function ($parse, loggerUISrv) {
        return {
            restrict: 'A',
            link: function (scope, element, attr) {
                var eventType = attr.loggerUi;
                if (eventType) {
                    scope.element = element;
                    element.on('click', function () {
                        scope.$apply(function () {
                            loggerUISrv.sendLog(eventType).then(function (response) {
                                if (!response.data.success) {
                                    console.log('не смог передать лог: ' + eventType)
                                }
                            }, function (reason) {
                                console.log(reason)
                            });
                        });
                    });
                }
            }
        }
    }]).service('loggerUiSrv', ['httpSrv', function (httpSrv) {
    var service = this;
    /**
     * Отправка логов на сервер
     * @param eventType - тип События (должен соответствовать service.constans)
     * @param params - дополнительные параметры которые надо изменить в ходе отправки события на сервер
     * @returns {Promise}
     */
    service.sendLog = function (eventType, params) {
        return new Promise(function (resolve, reject) {
            var data = service.constans[eventType];
            if (params) {
                data.success = params.success !== true || data.success;
                data.info = params.info || data.info;
                if(params.targetId)
                    data.targetId = params.targetId;
            }
            if (data) {
                httpSrv.http({
                    methods: 'POST',
                    url: '/frontlog/log/event',
                    data: data
                }).then(function (value) {
                    resolve(value)
                }, function (reason) {
                    reject(reason)
                })
            } else {
                reject("Не обнаружена константа для eventType - " + eventType)
            }
        })
    };
    /**
     * Подготовленные константы под запросы на сервер
     */
    service.constans = {
        SPECIAL_ACCOUNT: {
            methodName: 'specialAccounts',
            methodDescription: 'Переход на страницу Спецсчета',
            success: true,
            info: 'Спецсчета. Переход на страницу внутри КОМИТА ЭТП'
        },
        SPECIAL_ACCOUNT_LINK_RAIF: {
            methodName: 'specialAccounts',
            methodDescription: 'Запрос счета АО «Райффайзенбанк»',
            success: true,
            info: 'Спецсчета. Переход на сайт банка'
        },
        SPECIAL_ACCOUNT_LINK_BSPB: {
            methodName: 'specialAccounts',
            methodDescription: 'Открытие формы запроса счета СПб',
            success: true,
            info: 'Спецсчета. Открыть форму заявки'
        },
        SPECIAL_ACCOUNT_SEND_BSPB: {
            methodName: 'specialAccounts',
            methodDescription: 'Отправка запроса на резервирование счета',
            success: true,
            info: 'Спецсчета. Форма. Отправка запроса'
        },
        SPECIAL_ACCOUNT_BANNER_MOEDELO: {
            methodName: 'specialAccounts',
            methodDescription: 'Переход по баннеру Моё дело',
            success: true,
            info: 'Переход по баннеру Моё дело'
        },
        GUARANTEES: {
            methodName: 'guarantees',
            methodDescription: 'Абсолют открытая часть',
            success: true,
            info: 'Абсолют открытая часть'
        },
        BANKGUARANTEES: {
            methodName: 'bankGuarantees',
            methodDescription: 'Абсолют вход в ЛК',
            success: true,
            info: 'Абсолют вход в ЛК'
        },
        RAIFFEISEN: {
            methodName: 'raiffeisen',
            methodDescription: 'Райффайзен открытая часть',
            success: true,
            info: 'Райффайзен открытая часть'
        },
        VTB: {
            methodName: 'vtb',
            methodDescription: 'Переход ОЧ ВТБ',
            success: true,
            info: 'Переход ОЧ ВТБ'
        },
        RAIFFEISENGUARANTEES: {
            methodName: 'raiffeisenGuarantees',
            methodDescription: 'Райффайзен ЛК',
            success: true,
            info: 'Райффайзен ЛК'
        },
        BANK: {
            methodName: 'Bank',
            methodDescription: 'Переход в БГ',
            success: true,
            info: 'Переход в БГ'
        },
        BANK_GUARANTEE_LINK: {
            methodName: 'BankGuarantee',
            methodDescription: 'Открытие раздела БГ по ссылке',
            success: true,
            info: 'Открытие раздела БГ по ссылке'
        },
        BANK_GUARANTEE_CALC: {
            methodName: 'BankGuarantee',
            methodDescription: 'Расчет комиссии БГ',
            success: true,
            info: 'Расчет комиссии БГ'
        },
        LOKOBG: {
            methodName: 'BankBG',
            methodDescription: 'Локо открытая часть',
            success: true,
            info: 'Локо открытая часть'
        },
        LOKO_BANK: {
            methodName: 'BankLoko',
            methodDescription: 'Локо ЛК',
            success: true,
            info: 'Локо ЛК'
        },
        VTB_BANK: {
            methodName: 'BankVTB',
            methodDescription: 'ЛК ВТБ',
            success: true,
            info: 'ЛК ВТБ'
        },
        KINO_I_PRAVO: {
            methodName: 'specialAccounts',
            methodDescription: 'Баннер КП',
            success: true,
            info: 'Баннер КП'
        },
        BECOME_CUSTOMER: {
            methodName: 'becomeCustomer',
            methodDescription: 'Переход в раздел Как стать заказчиком',
            success: true,
            info: 'Переход в раздел Как стать заказчиком'
        },
        BECOME_SUPPLIER: {
            methodName: 'becomeSupplier',
            methodDescription: 'Переход в раздел Стать поставщиком',
            success: true,
            info: 'Переход в раздел Стать поставщиком'
        },
        OTHER_PROCEDURES: {
            methodName: 'otherProcedures',
            methodDescription: 'Переход в раздел Реестр закупок ЕИС',
            success: true,
            info: 'Переход в раздел Реестр закупок ЕИС'
        },
        PROCEDURE_EIS_OPEN: {
            methodName: 'procedureEisOpen',
            methodDescription: 'Переход на закупку в ЕИС',
            success: true,
            info: 'Переход на закупку в ЕИС'
        },
        COMPANY_VALIDATION: {
            methodName: 'companyValidation',
            methodDescription: 'Переход в раздел Проверка компании',
            success: true,
            info: 'Переход в раздел Проверка компании'
        },
        FRESCHDOC: {
            methodName: 'freshdoc',
            methodDescription: 'Переход на страницу конструктора документов',
            success: true,
            info: 'Переход на страницу конструктора документов'
        },
        TARIFFS: {
            methodName: 'tariffs',
            methodDescription: 'Переход на страницу тарифов',
            success: true,
            info: 'Переход на страницу тарифов'
        },
        BANK_GUARANTEE: {
            methodName: 'BankGuarantee',
            methodDescription: 'Переход в раздел БГ',
            success: true,
            info: 'Переход в раздел БГ'
        },
        BANK_GUARANTEE_VTB: {
            methodName: 'BankGuaranteeVTB',
            methodDescription: 'Перечень анкет ВТБ',
            success: true,
            info: 'Переход в раздел БГ'
        },
        CREATE_BANK_GUARANTEE_APP: {
            methodName: 'BankGuarantee',
            methodDescription: 'Открытие полной анкеты',
            success: true,
            info: 'Открытие полной анкеты'
        },
        CREATE_BANK_GUARANTEE_APP_VTB: {
            methodName: 'BankGuaranteeVTB',
            methodDescription: 'Создание анкеты ВТБ',
            success: true,
            info: 'Открытие полной анкеты'
        },
        CREATE_BANK_GUARANTEE_PRIMARY_APP: {
            methodName: 'BankGuarantee',
            methodDescription: 'Открытие короткой анкеты',
            success: true,
            info: 'Открытие короткой анкеты'
        },
        TIME_OUT_LOGOFF: {
            methodName: 'timeOutLogOff',
            methodDescription: 'Выход по таймауту',
            success: true,
            info: 'Выход по таймауту'
        },
        VIDEO: {
            methodName: 'Video',
            methodDescription: 'Переход на страницу с видеороликами',
            success: true,
            info: 'Переход на страницу с видеороликами'
        },
        PRICE_ANALYSIS: {
            methodName: 'PriceAnalysis',
            methodDescription: 'Переход в раздел анализ цен',
            success: true,
            info: 'Переход в раздел анализ цен'
        },
        PRICE_ANALYSIS_GENERATE: {
            methodName: 'PriceAnalysis',
            methodDescription: 'Построение отчета',
            success: true,
            info: 'Построение отчета'
        },
        PURCHASE_PLAN_UPDATE: {
            methodName: 'updatePurchasePlan',
            methodDescription: 'Обновление планa закупок',
            success: false,
            info: 'Обновление планa закупок'
        },
        ROBOT_SLIDE_CLICK: {
            methodName: 'robotSlideClick',
            methodDescription: 'Переход по слайду ВА',
            success: true,
            info: 'Переход по слайду ВА'
        },
        BG_AGENT_BANNER_CLICK: {
            methodName: 'bgAgentBannerClick',
            methodDescription: 'Баннер агенты',
            success: true,
            info: 'Баннер агенты'
        },
        BG_ADVERTISING_BANNER_CLICK: {
            methodName: 'bgAdvertisingBannerClick',
            methodDescription: 'Реклама БГ',
            success: true,
            info: 'Реклама БГ'
        },
        BG_AGENT_PRESENTATION_CLICK: {
            methodName: 'bgAgentPresentationClick',
            methodDescription: 'Презентация агентам',
            success: true,
            info: 'Презентация агентам'
        },
        WANNAFACTORING: {
            methodName: 'pushWannaFactoring',
            methodDescription: 'Кнопка факторинг',
            success: true,
            info: 'Кнопка факторинг'
        },
        FACTORING_BANNER: {
            methodName: 'factoringBannerClick',
            methodDescription: 'Баннер факторинг',
            success: true,
            info: 'Баннер факторинг'
        },
        FACTORING_CLAIM: {
            methodName: 'factoringClaimClick',
            methodDescription: 'Заявка А.Факторинг',
            success: true,
            info: 'Заявка А.Факторинг'
        },
        MCHD_SLIDE_CLICK: {
            methodName: 'mchdSlideClick',
            methodDescription: 'Переход по слайду МЧД',
            success: true,
            info: 'Переход по слайду МЧД'
        }
    }
}]);
